import { userConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';
// import { labelRoles } from '../utils/constants';
import i18n from '../i18n';

export const getMe = (data) => {
  const success = (user) => {
    return { type: userConstants.GET_ME, user };
  };

  return async (dispatch) => {
    // dispatch(request());

    try {
      const user = await api.get('users/getMe');
      dispatch(
        success({
          ...user.data.data.user,
          token: localStorage.getItem('token'),
        }),
      );
      localStorage.removeItem('user');
      localStorage.setItem('user', JSON.stringify(user.data.data.user));
      // callback(labelRoles[user.data.data.user.role]);
      // setSubmitting(false);
    } catch (error) {
      // setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        // dispatch(failure('Something went wrong'));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};
