import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import FileUpload from '../Core/FileUpload';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { IMAGETYPE } from '../../utils/constants';
import GlobalContext from '../../context/GlobalContext';

const EmployeeImageModal = ({
  showEmployeeImageModal,
  setShowEmployeeImageModal,
  maxFiles,
  setFiles,
  binaryImages,
  onDeleteImage,
}) => {
  const { setInfoImageSaveModal } = useContext(GlobalContext);
  const handleDeleteImage = (imagePath) => {
    onDeleteImage(imagePath);
  };
  const handleSubmit = async (uploadedFiles) => {
    let processedFiles = await Promise.all(
      [...uploadedFiles].map(async (img) => {
        if (img.type === 'existing') {
          const parts = img.preview.split('/');
          return parts[parts.length - 1];
        } else {
          const res = await fetch(img.preview);
          return res.blob();
        }
      }),
    );
    let existingFiles = [...uploadedFiles]
      .filter((img) => img.type === 'existing')
      .map((img) => {
        const parts = img.preview.split('/');
        return parts[parts.length - 1];
      });

    setShowEmployeeImageModal(false);
    setInfoImageSaveModal(true);
    const unsupportedImages = processedFiles.filter((img) => {
      return (
        typeof img !== 'string' &&
        img.type !== 'existing' &&
        !IMAGETYPE.includes(img.type)
      );
    });
    if (unsupportedImages.length > 0) {
      const supportedImages = processedFiles.filter((img) =>
        IMAGETYPE.includes(img.type),
      );
      processedFiles = [...supportedImages];
      toast.error(t('uploadValidImage'));
      setInfoImageSaveModal(false);
    }
    if (processedFiles.length > maxFiles) {
      toast.error(t('maximum10images'));
      setInfoImageSaveModal(false);
    } else {
      setFiles([...processedFiles]);
      setInfoImageSaveModal(true);
    }
  };

  return (
    <>
      <Modal
        show={showEmployeeImageModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowEmployeeImageModal(false)}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{t('imageModel.uploadImage')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="employee-images-modal">
            <p className="lead modal-body-title">
              {t('staffImageModal.imageNote')}
            </p>
            <form className="form form-image-upload">
              <FileUpload
                binaryImages={binaryImages}
                setFiles={setFiles}
                type="file"
                label=""
                placeholder=""
                name="companyLogo"
                handleSubmit={handleSubmit}
                onDeleteImage={handleDeleteImage}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployeeImageModal;
