import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import shortid from 'shortid';
import ManageInfoModal from './ManageInfoModal';
import BookingList from '../BookingList';

import GlobalContext from '../../context/GlobalContext';

const StaffAppliedJobs = (props) => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const { id } = useParams();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const eventsData = useSelector((state) => state.events?.allEvents?.events);
  const { showBookingList } = useContext(GlobalContext);

  useEffect(() => {
    const filteredJobs = props?.staff?.appliedJobs
      .filter((fJob) => {
        let bookedFlag = true;
        let currCompanyFlag = false;
        let rejected = false;
        const currJobPosition = fJob?.jobPosition;
        props?.staff?.bookedJobs?.map((bookedJob) => {
          if (fJob?.jobPosition === bookedJob?.jobPositionID) {
            bookedFlag = false;
          }
        });
        if (
          !currJobPosition ||
          currJobPosition?.rejectedApplicants?.find(
            (ja) => ja?.applicant?._id === id,
          )
        ) {
          rejected = true;
        }

        if (
          user?.data?.role === 'superuser' ||
          (fJob?.companyId &&
            fJob?.companyId === user?.data?.employer?._id)
        ) {
          currCompanyFlag = true;
        }
        return (
          fJob?.jobPosition &&
          currCompanyFlag &&
          !rejected
        );
      })
      .map((mJob) => {
        let otherUserBooked = false;
        const job = mJob.jobID;
        const position = mJob?.jobPosition;
        if (position?.booked && position?.booked?.employee) {
          otherUserBooked = true;
        }

        if (
          position?.readyToBook?.applicant &&
          position?.readyToBook?.applicant !== props?.staff._id
        ) {
          otherUserBooked = true;
        }

        if (
          !position ||
          position.rejectedApplicants.find((ja) => ja.applicant === id) ||
          otherUserBooked
        ) {
          return {};
        }

        return {
          eventID: mJob.eventID,
          jobID: mJob.jobID,
          jobPositionId: mJob.jobPosition._id,
          event: mJob.eventName,
          jobName: mJob.jobName,
          position: position,
          place: position?.jobPlace,
          date: position?.jobDate,
          start: position?.jobStart,
          end: position?.jobEnd,
          price: `€ ${Number(position?.jobPrice).toFixed(2)}`,
          travelCosts: `€ ${Number(position?.jobTravelCost).toFixed(2)}`,
          totalCosts: `€ ${Number(
            position?.jobPrice + position?.jobTravelCost,
          ).toFixed(2)}`,
        };
      });

    setAppliedJobs(filteredJobs);
  }, [props?.staff, eventsData]);

  return (
    <>
      {showBookingList && (
        <BookingList
          OffcanvasProps={{ placement: 'end', name: 'bookingList' }}
          fetchStaffDetails={props?.fetchStaffDetails}
        />
      )}

      {/*{props?.loading && <Loader />}*/}
      <div className="form application-form  mt-5">
        <h3>{t('applied')}</h3>

        {appliedJobs?.length > 0 ? (
          appliedJobs?.map((job) => {
            if (JSON.stringify(job) !== '{}') {
              const {
                event,
                jobName,
                jobPositionId,
                place,
                date,
                start,
                end,
                price,
                travelCosts,
                totalCosts,
              } = job;
              return (
                <div
                  className="application-form-wrapper"
                  key={shortid.generate()}>
                  <div className="job-list__item">
                    <Row>
                      <Col lg={5} xxl={5}>
                        <Row>
                          <Col sm={3}>
                            <div className="form-group">
                              <label className="form-label">
                                {t('profile.event')}
                              </label>
                              <p className="fields-text small-text">{event}</p>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="form-group">
                              <label className="form-label">
                                {t('profile.jobPosition')}
                              </label>
                              <p className="fields-text small-text">
                                {jobName}
                              </p>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="form-group">
                              <label className="form-label">
                                {t('placeLabel')}
                              </label>
                              <p className="fields-text small-text">{place}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={7} xxl={4}>
                        <Row>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">
                                {t('dateLabel')}
                              </label>
                              <p className="fields-text small-text">
                                {moment(date).format('dddd DD.MM.yyyy')}
                              </p>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">
                                {t('startLabel')}
                              </label>
                              <p className="fields-text small-text">
                                {moment(start).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')}
                              </p>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">
                                {t('endLabel')}
                              </label>
                              <p className="fields-text small-text">
                                {moment(end).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')}
                              </p>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="form-group">
                              <label className="form-label">
                                {t('Costs')}
                              </label>
                              <p className="fields-text small-text">
                                €{' '}
                                {Number(
                                  job?.position?.employerData?.jobPrice
                                    ? job?.position?.employerData?.jobPrice
                                    : 0,
                                ).toFixed(2)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={7} md={5} xxl={3}>
                        <Row>
                          <Col sm="6">
                            <div className="form-group">
                              <label className="form-label">
                                {t('requestOverview.travelCosts')}
                              </label>
                              <p className="fields-text small-text">
                                €{' '}
                                {Number(
                                  job?.position?.employerData?.jobTravelCost
                                    ? job?.position?.employerData?.jobTravelCost
                                    : 0,
                                ).toFixed(2)}
                              </p>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="form-group text-sm-end">
                              <label className="form-label">
                                {t('requestOverview.totalCosts')}
                              </label>
                              <p className="fields-text small-text">
                                <strong className="total-amount">
                                  €{' '}
                                  {Number(
                                    job?.position?.employerData?.jobTotalCost
                                      ? job?.position?.employerData
                                          ?.jobTotalCost
                                      : 0,
                                  ).toFixed(2)}
                                </strong>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <ManageInfoModal
                    job={job}
                    applicant_id={id}
                    jobPositionId={jobPositionId}
                    position={job?.position}
                    showInfoModal={showInfoModal}
                    setShowInfoModal={setShowInfoModal}
                    staff={props?.staff}
                    fetchStaffDetails={props?.fetchStaffDetails}
                    loading={props?.loading}
                    setLoading={props?.setLoading}
                  />
                </div>
              );
            }
          })
        ) : (
          <p>{t('notApplied')}</p>
        )}
      </div>
    </>
  );
};

export default StaffAppliedJobs;
