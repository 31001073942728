import React, { useState } from 'react';
import moment from 'moment';
import Head from '../components/Head';
import { Container, Row, Col } from 'react-bootstrap';
import Input from '../components/Core/Input';
import DatePicker from 'react-datepicker';
import Textarea from '../components/Core/Textarea';
import CustomSelect from '../components/Core/Select';
import Button from '../components/Core/Button';
import { Link } from 'react-router-dom';
import { bonusOptions } from '../components/Modal/NewJob';
import { useTranslation } from 'react-i18next';

const adddressOptions = [
  {
    value: '1',
    label: 'London | Shoreditch Gardens, Garden Atrium Kingsland Rd,',
  },
  { value: '2', label: 'London | Autocomplete Address 2, Line 2 A5, 8FB' },
  { value: '3', label: 'London | Autocomplete Address 2, Line 2 A5, 8FB' },
  { value: '4', label: 'London | Autocomplete Address 2, Line 2 A5, 8FB' },
];

const NewRequest = () => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());

  return (
    <form className="form">
      <section className="content-section">
        <Head title={t('title.requestDetails')} />
        <Container>
          {/* New request */}
          <div className="box-wrapper form-box-wrapper new-request-block">
            <h3>{t('newRequest')}</h3>
            <Row>
              <Col md="12" xl="6">
                <Row>
                  <Col>
                    <Row>
                      <Col sm="2" md="3" xl="3">
                        <div className="form-group">
                          <label className="form-label">
                            {t('requestOverview.eventId')}
                          </label>
                          <div className="field-info">214.234</div>
                        </div>
                      </Col>
                      <Col sm="6" md="6" xl="5">
                        <Input
                          type="text"
                          label={t('requestOverview.eventName') + '*'}
                          placeholder={t('eventNameplaceholder')}
                          name="eventName"
                        />
                      </Col>
                      <Col sm="3" md="3" xl="3">
                        <div className="form-group date-picker-group">
                          <label className="form-label">
                            {t('requestOverview.expirationDate') + '*'}
                          </label>
                          <DatePicker
                            minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd.MM.yy"
                            calendarStartDay={1}
                            className="form-control"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {/* Jobs List */}
          <div className="box-wrapper form-box-wrapper job-list">
            <div className="job-list__header">
              <h3>JOB 01: Hostess m/f</h3>
              <Row>
                <Col sm="6" xl="5">
                  <Textarea
                    label={t('requestOverview.description')}
                    placeholder={t(
                      'requestOverview.modal.pleaseAddDescription',
                    )}
                    name="description"
                    rows="2"
                  />
                </Col>
                <Col sm="6" xl="5">
                  <Textarea
                    label={t('requestOverview.staffRequirement')}
                    placeholder={t(
                      'requestOverview.modal.pleaseAddDescription2',
                    )}
                    name="staffRequirment"
                    rows="2"
                  />
                </Col>
                <Col sm="4" xl="2">
                  <CustomSelect
                    label={t('requestOverview.bonus')}
                    name="bonus"
                    options={bonusOptions}
                    classNamePrefix="c-select"
                    tooltipTitle={t('requestOverview.bonus')}
                    tooltipText={t('requestOverview.modal.bonusTooltip')}
                  />
                </Col>
              </Row>
            </div>

            <div className="job-list__item">
              <Row>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="2">
                      <div className="numberList">1/1</div>
                    </Col>
                    <Col sm="6" lg="4">
                      <div className="form-group date-picker-group">
                        <label className="form-label">
                          {t('dateLabel') + '*'}
                        </label>
                        <DatePicker
                          minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="EEEE, dd.MM.yy"
                          calendarStartDay={1}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6">
                      <CustomSelect
                        label={t('placeplaceholder') + '*'}
                        name="place"
                        options={adddressOptions}
                        classNamePrefix="request-details-select c-select"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('startLabel') + '*'}
                        placeholder="00:00"
                        value="10:00"
                        name="startTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('endLabel') + '*'}
                        value="15:30"
                        placeholder="00:00"
                        name="endTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.price')}
                        </label>
                        <div className="fields-text">€ XYZ</div>
                      </div>
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.travelCosts')}
                        </label>
                        <div className="fields-text">€ —-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Row>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.modal.duplicate')}
                        </label>
                        <div className="field-action-icon">
                          <svg
                            width="21"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(.5)"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                stroke="#41A9C7"
                                strokeWidth="2"
                                x="1"
                                y="5"
                                width="13"
                                height="17"
                                rx="2"
                              />
                              <g fill="#41A9C7">
                                <path d="M6 10h3v7H6z" />
                                <path d="M4 15v-3h7v3z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">{t('delete')}</label>
                        <div className="field-action-icon">
                          <svg
                            width="19"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                              fill="#41A9C7"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="job-list__item">
              <Row>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="2">
                      <div className="numberList">1/1</div>
                    </Col>
                    <Col sm="6" lg="4">
                      <div className="form-group date-picker-group">
                        <label className="form-label">
                          {t('dateLabel') + '*'}
                        </label>
                        <DatePicker
                          minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="EEEE, dd.MM.yy"
                          calendarStartDay={1}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6">
                      <CustomSelect
                        label={t('placeplaceholder') + '*'}
                        name="place"
                        options={adddressOptions}
                        classNamePrefix="request-details-select c-select"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('startLabel') + '*'}
                        placeholder="00:00"
                        value="10:00"
                        name="startTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('endLabel') + '*'}
                        value="15:30"
                        placeholder="00:00"
                        name="endTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.price')}
                        </label>
                        <div className="fields-text">€ XYZ</div>
                      </div>
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.travelCosts')}
                        </label>
                        <div className="fields-text">€ —-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Row>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.modal.duplicate')}
                        </label>
                        <div className="field-action-icon">
                          <svg
                            width="21"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(.5)"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                stroke="#41A9C7"
                                strokeWidth="2"
                                x="1"
                                y="5"
                                width="13"
                                height="17"
                                rx="2"
                              />
                              <g fill="#41A9C7">
                                <path d="M6 10h3v7H6z" />
                                <path d="M4 15v-3h7v3z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">{t('delete')}</label>
                        <div className="field-action-icon">
                          <svg
                            width="19"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                              fill="#41A9C7"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <Row>
              <Col className="text-right">
                <Link to="" className="decorated-link">
                  {t('requestOverview.addJobPosition')}
                </Link>
              </Col>
            </Row>
          </div>

          <div className="box-wrapper form-box-wrapper job-list">
            <div className="job-list__header">
              <h3>JOB 02: Service Staff m/f</h3>
              <Row>
                <Col sm="6" xl="5">
                  <Textarea
                    label={t('requestOverview.description')}
                    placeholder={t(
                      'requestOverview.modal.pleaseAddDescription',
                    )}
                    name="description"
                    rows="2"
                  />
                </Col>
                <Col sm="6" xl="5">
                  <Textarea
                    label={t('requestOverview.staffRequirement')}
                    placeholder={t(
                      'requestOverview.modal.pleaseAddDescription2',
                    )}
                    name="staffRequirment"
                    rows="2"
                  />
                </Col>
                <Col sm="4" xl="2">
                  <CustomSelect
                    label={t('requestOverview.bonus')}
                    name="bonus"
                    options={bonusOptions}
                    classNamePrefix="c-select"
                    tooltipTitle={t('requestOverview.bonus')}
                    tooltipText={t('requestOverview.modal.bonusTooltip')}
                  />
                </Col>
              </Row>
            </div>

            <div className="job-list__item">
              <Row>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="2">
                      <div className="numberList">1/1</div>
                    </Col>
                    <Col sm="6" lg="4">
                      <div className="form-group date-picker-group">
                        <label className="form-label">
                          {t('dateLabel') + '*'}
                        </label>
                        <DatePicker
                          minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="EEEE, dd.MM.yy"
                          calendarStartDay={1}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6">
                      <CustomSelect
                        label={t('placeplaceholder') + '*'}
                        name="place"
                        options={adddressOptions}
                        classNamePrefix="request-details-select c-select"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('startLabel') + '*'}
                        placeholder="00:00"
                        value="10:00"
                        name="startTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('endLabel') + '*'}
                        value="15:30"
                        placeholder="00:00"
                        name="endTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.price')}
                        </label>
                        <div className="fields-text">€ XYZ</div>
                      </div>
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.travelCosts')}
                        </label>
                        <div className="fields-text">€ —-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Row>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.modal.duplicate')}
                        </label>
                        <div className="field-action-icon">
                          <svg
                            width="21"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(.5)"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                stroke="#41A9C7"
                                strokeWidth="2"
                                x="1"
                                y="5"
                                width="13"
                                height="17"
                                rx="2"
                              />
                              <g fill="#41A9C7">
                                <path d="M6 10h3v7H6z" />
                                <path d="M4 15v-3h7v3z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">{t('delete')}</label>
                        <div className="field-action-icon">
                          <svg
                            width="19"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                              fill="#41A9C7"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="job-list__item">
              <Row>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="2">
                      <div className="numberList">1/1</div>
                    </Col>
                    <Col sm="6" lg="4">
                      <div className="form-group date-picker-group">
                        <label className="form-label">
                          {t('dateLabel') + '*'}
                        </label>
                        <DatePicker
                          minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="EEEE, dd.MM.yy"
                          calendarStartDay={1}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6">
                      <CustomSelect
                        label={t('placeplaceholder') + '*'}
                        name="place"
                        options={adddressOptions}
                        classNamePrefix="request-details-select c-select"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('startLabel') + '*'}
                        placeholder="00:00"
                        value="10:00"
                        name="startTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('endLabel') + '*'}
                        value="15:30"
                        placeholder="00:00"
                        name="endTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.price')}
                        </label>
                        <div className="fields-text">€ XYZ</div>
                      </div>
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.travelCosts')}
                        </label>
                        <div className="fields-text">€ —-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Row>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.modal.duplicate')}
                        </label>
                        <div className="field-action-icon">
                          <svg
                            width="21"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(.5)"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                stroke="#41A9C7"
                                strokeWidth="2"
                                x="1"
                                y="5"
                                width="13"
                                height="17"
                                rx="2"
                              />
                              <g fill="#41A9C7">
                                <path d="M6 10h3v7H6z" />
                                <path d="M4 15v-3h7v3z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">{t('delete')}</label>
                        <div className="field-action-icon">
                          <svg
                            width="19"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                              fill="#41A9C7"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="job-list__item">
              <Row>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="2">
                      <div className="numberList">1/1</div>
                    </Col>
                    <Col sm="6" lg="4">
                      <div className="form-group date-picker-group">
                        <label className="form-label">
                          {t('dateLabel') + '*'}
                        </label>
                        <DatePicker
                          minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="EEEE, dd.MM.yy"
                          calendarStartDay={1}
                          className="form-control"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6">
                      <CustomSelect
                        label={t('placeplaceholder') + '*'}
                        name="place"
                        options={adddressOptions}
                        classNamePrefix="request-details-select c-select"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('startLabel') + '*'}
                        placeholder="00:00"
                        value="10:00"
                        name="startTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <Input
                        type="text"
                        label={t('endLabel') + '*'}
                        value="15:30"
                        placeholder="00:00"
                        name="endTime"
                        className="time-fields"
                      />
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.price')}
                        </label>
                        <div className="fields-text">€ XYZ</div>
                      </div>
                    </Col>
                    <Col xs="6" lg="3">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.travelCosts')}
                        </label>
                        <div className="fields-text">€ —-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Row>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">
                          {t('requestOverview.modal.duplicate')}
                        </label>
                        <div className="field-action-icon">
                          <svg
                            width="21"
                            height="23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(.5)"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                stroke="#41A9C7"
                                strokeWidth="2"
                                x="1"
                                y="5"
                                width="13"
                                height="17"
                                rx="2"
                              />
                              <g fill="#41A9C7">
                                <path d="M6 10h3v7H6z" />
                                <path d="M4 15v-3h7v3z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col xs="6" sm="12" lg="6 text-sm-center">
                      <div className="form-group">
                        <label className="form-label">{t('delete')}</label>
                        <div className="field-action-icon">
                          <svg
                            width="19"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                              fill="#41A9C7"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <Row>
              <Col className="text-right">
                <Link to="" className="decorated-link">
                  {t('requestOverview.addJobPosition')}
                </Link>
              </Col>
            </Row>
          </div>

          {/* New Jobs */}
          <div className="box-wrapper form-box-wrapper new-job-block">
            <h3>{t('newRequestOrg.newJobLabel')}</h3>
            <Row>
              <Col className="text-right">
                <Link to="" className="decorated-link">
                  {t('addJobLabel')}
                </Link>
              </Col>
            </Row>
          </div>

          {/* New Jobs */}
          <div className="form-action-wrapper justify-content-end">
            <Button
              type="submit"
              label={t('requestOverview.modal.cancel')}
              className="btn btn--outline"
            />
            <Button
              type="submit"
              label={t('newRequestOrg.btnRequest')}
              className="btn primary-btn"
            />
          </div>
        </Container>
      </section>
    </form>
  );
};

export default NewRequest;
