import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { useTable, useSortBy } from 'react-table';
import { Container } from 'react-bootstrap';
import Head from '../components/Head';
import Button from '../components/Core/Button';
import { labelRoles } from '../utils/constants';
import apiv2 from '../utils/apiv2';
import { handleError } from '../utils/handleError';
import { toast } from 'react-toastify';
import Loader from '../components/Core/Loader';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { convertMsToHM } from '../utils/time';
import { loadStripe } from '@stripe/stripe-js/pure';
loadStripe.setLoadParameters({ advancedFraudSignals: false });
// eslint-disable-next-line import/first
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';

const Table = ({ columns, data }) => {
  const { t, i18n } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table
        className="table remove-space request-payment-table"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'costs') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={shortid.generate()}
                          className="no-wrap-cls">
                          <span className="total-amount">
                            {cell.render('Cell')}
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} key={shortid.generate()}>
                          <span>{cell.render('Cell')}</span>
                        </td>
                      );
                    }
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TableAddress = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table className="table show-address-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={shortid.generate()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TablePayment = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table className="table show-address-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={shortid.generate()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CheckoutForm1 = ({ invoice, setLoading }) => {
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  let dispatch = useDispatch();
  const elements = useElements();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const paymentmethod = location.state?.paymentMehods;

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
  }, [message]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!invoice) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    if (!user.data.employer.billingAddress) {
      toast.error(t('billingaddressdetails'));
      return;
    }

    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!paymentmethod) {
      return;
    } else {
      setIsLoading(true);
      setLoading(true);
      try {
        const result = await apiv2.post('/order/completeOrder', {
          invoiceId: invoice.id,
        });
        const job = location.state.jobs[0];
        const paymentResult = await stripe.confirmCardPayment(
          String(result.data.data.client_secret),
          {
            payment_method: paymentmethod,
            receipt_email: user.data.email,
          },
        );
        if (paymentResult.error) {
          setIsLoading(false);
          setLoading(false);
          toast.error(paymentResult.error.message);
        } else {
          if (paymentResult.paymentIntent.status === 'succeeded') {
            const employee = location.state.employee;
            if (location?.state?.payOutStanding === true) {
              const role = user.data.role;
              const rolePath =
                role === 'superuser'
                  ? labelRoles.superuser
                  : labelRoles.employer;

              setTimeout(() => {
                toast.success(t('outstandingamountpaid'));
                history.push({
                  pathname: `/${rolePath}/payment-completed`,
                  state: {
                    stripeorderid: paymentResult.paymentIntent.id,
                  },
                });
              }, 20000);
            } else {
              const role = user.data.role;
              const rolePath =
                role === 'superuser'
                  ? labelRoles.superuser
                  : labelRoles.employer;

              toast.success(t('staffbooked'));
              history.push({
                pathname: `/${rolePath}/payment-completed`,
                state: {
                  stripeorderid: paymentResult.paymentIntent.id,
                },
              });
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
        setLoading(false);
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn primary-btn m-3">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              {t('PayNow')}
            </div>
          ) : (
            `${t('PayNow')}`
          )}
        </span>
      </button>
      {/*Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const RequestPaymentSummary = () => {
  let dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [totalCost, setTotalCost] = useState(0);

  const [totalCostOutstanding, setTotalCostOutstanding] = useState(0);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const userData = user.data;
  const { employer } = userData;

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const invoice = location.state.invoice;
  const taxRate = invoice?.default_tax_rates[0]?.percentage || 20;
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    invoice,
    appearance,
  };

  useEffect(() => {
    let mounted = true;
    if (
      !location.state ||
      !location.state.jobs ||
      !location.state.jobs.length
    ) {
      if (mounted) {
        if (userData.role === 'superuser') {
          history.push(`/${labelRoles.superuser}/eventslist`);
        } else {
          history.push(`/${labelRoles.employer}/request-overview`);
        }
      }
    } else if (location.state.jobs && location.state.jobs.length) {
      let localTotalCost = 0;
      let localTotalCostOutstanding = 0;
      location.state.jobs.map((job, id) => {
        if (location?.state?.payOutStanding) {
          if (job?.employerData?.paidOutstandingAmount !== true) {
            localTotalCostOutstanding += job?.employerData?.outstandingAmount
              ? job?.employerData?.outstandingAmount
              : 0;
          }
        } else {
          localTotalCost += job?.employerData?.jobTotalCost;
        }
      });

      setTotalCost(localTotalCost);
      setTotalCostOutstanding(localTotalCostOutstanding);
    }
    return () => (mounted = false);
  }, [location]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'id',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventId')}`;
        },
        accessor: 'eventId',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobType')}`;
        },
        accessor: 'jobType',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobDate')}`;
        },
        accessor: 'jobDate',
      },
      {
        Header: () => {
          return `${t('event.hours')}`;
        },
        accessor: 'hours',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobCity')}`;
        },
        accessor: 'jobCity',
      },
      {
        Header: () => {
          return `${t('Costs')}`;
        },
        accessor: 'costs',
      },
    ];
  }, []);

  const addcolumns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('profilebilldetailname')}`;
        },
        accessor: 'name',
      },
      {
        Header: () => {
          return `${t('profileOrg.billAddress')}`;
        },
        accessor: 'street',
      },
      {
        Header: () => {
          return `${t('postalCode')}`;
        },
        accessor: 'postCode',
      },
      {
        Header: () => {
          return `${t('city')}`;
        },
        accessor: 'city',
      },
      {
        Header: () => {
          return `${t('country')}`;
        },
        accessor: 'country',
      },
    ];
  }, []);

  const addressData = React.useMemo(() => {
    const billingAddress = user.data.employer?.billingAddress;
    return [
      {
        name: billingAddress?.billingAddressName,
        street: `${billingAddress?.billingAddressStreet} ${
          billingAddress?.billingAddressStreetTwo
            ? '/ ' + billingAddress?.billingAddressStreetTwo
            : ''
        }`,
        postCode: billingAddress?.billingAddressPostCode,
        city: billingAddress?.billingAddressCity,
        country: billingAddress?.billingAddressCountry,
      },
    ];
  }, []);

  const paycolumns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('cardMethod')}`;
        },
        accessor: 'method',
      },
      {
        Header: () => {
          return `${t('cardName')}`;
        },
        accessor: 'name',
      },
      {
        Header: () => {
          return `${t('cardNumberplaceholder')}`;
        },
        accessor: 'number',
      },
      {
        Header: () => {
          return `${t('requestOverview.expirationDate')}`;
        },
        accessor: 'expDate',
      },
    ];
  }, []);

  const paydata = React.useMemo(() => {
    if (location && location.state && location.state.paymentDetails) {
      const paymentDetails = location.state.paymentDetails;
      return [
        // first row data
        {
          method: 'Visa Credit Card',
          name: paymentDetails.cardName,
          number: paymentDetails.cardNumber,
          expDate: paymentDetails.validThrough,
        },
      ];
    } else {
      return [];
    }
  }, [location]);

  const renderTable = (jobs) => {
    const data = jobs.map((job, id) => {
      let cost = 0;
      if (location?.state?.payOutStanding) {
        if (job?.employerData?.paidOutstandingAmount !== true) {
          cost += job?.employerData?.outstandingAmount;
        }
      } else {
        cost += job?.employerData?.confirmJobTotalCost
          ? job?.employerData?.confirmJobTotalCost
          : job?.employerData?.jobTotalCost
          ? job?.employerData?.jobTotalCost
          : 0;
      }
      return {
        id: `${id + 1}`,
        eventId: job?.eventId ? job?.eventId : job?.eventID,
        eventName: job?.eventName,
        jobType: job?.jobName,
        jobDate: moment(job?.jobDate).tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY'),

        hours: `${convertMsToHM(
          (job?.confirmJobEnd ? job?.confirmJobEnd : job?.jobEnd) -
            (job?.confirmJobStart ? job?.confirmJobStart : job?.jobStart),
        )}`,
        jobCity: job?.jobPlaceAddress?.city,
        typeId: 'Outstanding amount extra hours',
        costs: `€ ${Number(cost ? cost : 0).toFixed(2)}`,
      };
    });
    return <Table columns={columns} data={data} />;
  };
  return (
    <section className="content-section">
      <Head title={t('title.requestPaymentSummary')} />
      <Container>
        {loading && <Loader />}
        <div className="box-wrapper">
          <div
            className="form-back"
            onClick={() => {
              if (userData.role === 'superuser') {
                // history.push(`/${labelRoles.superuser}/eventslist`);
                history.push(`/${labelRoles.superuser}/request-pay`);
              } else {
                // history.push(`/${labelRoles.employer}/request-overview`);
                history.push(`/${labelRoles.employer}/request-pay`);
              }
            }}></div>
          <div className="book-pay-details">
            <h3>{t('summary')}</h3>
            {location && location.state && renderTable(location.state.jobs)}
            {/*<div className="d-flex justify-content-end align-items-center mb-4">
              <span className="discount-text">{t('PromotionalDiscount')} </span>
              <span className="total-amount">€ {discount}*</span>
          </div>*/}
            <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="placeholder-text ms-0"></div>
              <div className="d-flex flex-column space-between align-items-end">
                <div className="d-flex w-100 justify-content-between">
                  <div className="placeholder-text align-self-center">
                    {t('event.totalExcl')}
                  </div>
                  <div className="total-amount">
                    {' '}
                    €{' '}
                    {totalCostOutstanding > 0
                      ? Number(totalCostOutstanding).toFixed(2)
                      : Number(totalCost).toFixed(2)}
                  </div>
                </div>
                <div className="d-flex mt-1 w-100 justify-content-between">
                  <div className="placeholder-text align-self-center">
                    {t('taxRate')}
                  </div>
                  <div className="total-amount align-content-end">
                    €{' '}
                    {totalCostOutstanding > 0
                      ? Number((totalCostOutstanding * taxRate) / 100).toFixed(
                          2,
                        )
                      : Number((totalCost * taxRate) / 100).toFixed(2)}
                  </div>
                </div>
                <div className="d-flex mt-1 w-100 justify-content-between">
                  <div className="placeholder-text align-self-center">
                    {t('event.totalIncl')}
                  </div>
                  <div className="total-amount">
                    €{' '}
                    {totalCostOutstanding > 0
                      ? Number(
                          (totalCostOutstanding * (1 + taxRate / 100)).toFixed(
                            2,
                          ),
                        ).toFixed(2)
                      : Number(totalCost * (1 + taxRate / 100)).toFixed(2)}{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="billing-details">
            <h3 className="headline-with-edit">
              <span>{t('profileOrg.billAddress')}</span>
            </h3>
            <TableAddress columns={addcolumns} data={addressData} />
          </div>
          {paydata && paydata.length ? (
            <div className="payment-detail-wrapper border-bottom-0">
              <h3>{t('profileOrg.payment')}</h3>
              <TablePayment columns={paycolumns} data={paydata} />
            </div>
          ) : (
            <></>
          )}
          <div className="form-bottom justify-content-end">
            {location.state.invoice ? (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm1
                  invoice={invoice}
                  setLoading={setLoading}
                  totalCostOutstanding={totalCostOutstanding}
                  totalCost={totalCost}
                />
              </Elements>
            ) : (
              <Button
                type="submit"
                label={t('PayNow')}
                className="btn primary-btn"
                disabled={paymentLoading}
                onClick={async () => {
                  if (!user.data.employer.billingAddress) {
                    toast.error(t('billingaddressdetails'));
                    return;
                  }
                  setPaymentLoading(true);
                  setLoading(true);
                  try {
                    const isInvoice = location.state.isInvoice;
                    const jobpositionids = location.state.jobs
                      .filter(
                        (job) =>
                          !job.isConfirmed ||
                          (!job.paidOutstandingAmount &&
                            !isNaN(job.outstandingAmount) &&
                            job.outstandingAmount > 0),
                      )
                      .map((job) => ({
                        id: job._id,
                        eventId: job.eventId || job.eventID,
                      }));
                    let createChargeRes;
                    if (isInvoice) {
                      try {
                        createChargeRes = await apiv2
                          .post('/invoice-payment', {
                            jobids: jobpositionids,
                            employer: {
                              companyName: employer.companyName
                                ? employer.companyName
                                : '',
                              country: employer.country ? employer.country : '',
                              postalCode: employer.postalCode
                                ? employer.postalCode
                                : '',
                              city: employer.city ? employer.city : '',
                              addressLineOne: employer.addressLineOne
                                ? employer.addressLineOne
                                : '',
                              VAT: employer.companyVatNumber
                                ? employer.companyVatNumber
                                : '',
                              billingAddress: {
                                billingAddressName:
                                  employer?.billingAddress?.billingAddressName,
                                billingAddressCountry:
                                  employer?.billingAddress
                                    ?.billingAddressCountry,
                                billingAddressCity:
                                  employer?.billingAddress?.billingAddressCity,
                                billingAddressPostCode:
                                  employer?.billingAddress
                                    ?.billingAddressPostCode,
                                billingAddressStreet:
                                  employer?.billingAddress
                                    ?.billingAddressStreet,
                                billingAddressStreetTwo:
                                  employer?.billingAddress
                                    ?.billingAddressStreetTwo,
                              },
                            },
                            employeeId: location?.state?.employee?._id,
                            outstandingpay:
                              totalCostOutstanding > 0 ? true : false,
                          })
                          .catch((error) => {
                            if (error.response.status === 400) {
                              toast.error(error.response.data.message);
                            } else if (error.response.status !== 200) {
                              toast.error(t('employeeAlreadyBookForaJob'));
                            }
                          });
                      } catch (error) {
                        toast.error(t('employeeAlreadyBookForaJob'));
                        setLoading(false);
                        setPaymentLoading(false);
                        return;
                      }
                    }

                    const data = createChargeRes.data;
                    setPaymentLoading(false);
                    if (data.status !== 'error') {
                      const paymentSummaryPath =
                        userData.role === 'superuser'
                          ? `/${labelRoles.superuser}/payment-completed`
                          : `/${labelRoles.employer}/payment-completed`;
                      setTimeout(() => {
                        toast.success(
                          location?.state?.payOutStanding
                            ? t('outstandingamountpaid')
                            : t('staffbooked'),
                        );
                        history.push({
                          pathname: paymentSummaryPath,
                          state: {
                            stripeorderid: data.data.invoiceId,
                          },
                        });
                      }, 20000);
                      setPaymentLoading(false);
                    } else {
                      setLoading(false);
                      let message =
                        data.data.error &&
                        data.data.error.raw &&
                        data.data.error.raw.message
                          ? data.data.error.raw.message.replace(
                              'value',
                              data.data.error.param,
                            )
                          : null;
                      if (message) {
                        toast.error(message);
                      } else {
                        toast.error(t('paymentfailed'));
                      }
                      return;
                    }
                  } catch (error) {
                    setLoading(false);
                    setPaymentLoading(false);
                    handleError(error);
                  }
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RequestPaymentSummary;
