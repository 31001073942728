/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import shortid from 'shortid';
import { useTable, useExpanded, useSortBy } from 'react-table';
import { Container, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { Link, useLocation } from 'react-router-dom';
import Head from '../components/Head';
import GlobalContext from '../context/GlobalContext';
import JobPosition from '../components/Modal/JobPosition';
import NewJob from '../components/Modal/NewJob';
import StaffSelection from '../components/Modal/StaffSelection';
import Brief from '../components/Modal/Brief';
import Loader from '../components/Core/Loader';
import Button from '../components/Core/Button';
import { labelRoles } from '../utils/constants';
import {
  checkBookedStaff,
  htmlDecode,
  constructGCSImageUrl,
} from '../utils/helper';
import { fetchEvents, updateEvent } from '../actions/eventAction';
import { updateJob } from '../actions/jobAction';
import { toast } from 'react-toastify';
import BookingList from '../components/BookingList';
import { ReactComponent as CartIcon } from '../assets/images/icons/cart.svg';
import BriefingIcon from '../assets/images/icons/briefing.svg';
import DeleteJobModal from '../components/Modal/DeleteJobModal';
import api from '../utils/api';
import BookedStaffImage from '../components/Staff/BookedStaffImage';
import defaultAvatar from '../assets/images/default-avatar.png';

// import draftToHtml from 'draftjs-to-html';
import '../i18n';
registerLocale('de', de);
registerLocale('en', enUS);

const Table = ({
  columns: userColumns,
  currJob,
  setCurrJob,
  data,
  showNewJobModal,
  setShowNewJobModal,
  renderRowSubComponent,
  setEventLoading,
  expanded,
  setExpanded,
  stateReducer,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    isAllRowsExpanded,
    toggleRowExpanded,
    // eslint-disable-next-line no-unused-vars
  } = useTable(
    {
      columns: userColumns,
      data,
      stateReducer,
      autoResetExpanded: false,
      disableSortRemove: true,
      initialState: {
        expanded: expanded,
      },
    },
    useSortBy,
    useExpanded, // We can useExpanded to track the expanded state
    // for sub components too!
  );

  const getJobDuration = (job) => {
    let startDates = [],
      endDates = [];

    job.jobPositions.map((position) => {
      startDates = [...startDates, position.jobStart];
      endDates = [...endDates, position.jobEnd];
      return position;
    });

    if (startDates && startDates.length && endDates && endDates.length) {
      const startingDate = moment(Math.min(...startDates)).tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY');
      const endingDate = moment(Math.max(...endDates)).tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY');
      if (startingDate === endingDate) {
        return startingDate;
      } else {
        return `${startingDate} - ${endingDate}`;
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table request-list" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.id === 'eventId' ? 'ps-0' : ''}
                    style={{
                      textAlign:
                        column.id === 'amountBooked' ||
                        column.id === 'finalAmount'
                          ? 'right'
                          : '',
                    }}
                    key={shortid.generate()}>
                    {column.render('Header')}
                    {(column.id === 'eventId' ||
                      column.id === 'eventName' ||
                      column.id === 'expirationDate') && (
                      <span
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className={`${
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'sort-arrow sort-arrow-descending'
                              : 'sort-arrow sort-arrow-accending'
                            : 'sort-arrow'
                        }`}></span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={shortid.generate()}>
                  <tr
                    className={`parent-tab ${
                      row.isExpanded ? 'tab--open' : ''
                    }`}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'amountBooked') {
                        return (
                          <td key={shortid.generate()}>
                            <span
                              className="total-amount"
                              style={{ float: 'right' }}>
                              {cell.render('Cell')}
                            </span>
                          </td>
                        );
                      } else if (cell.column.id === 'finalAmount') {
                        return (
                          <td key={shortid.generate()}>
                            <span
                              className="total-amount final-amount right-align"
                              style={{ float: 'right' }}>
                              {cell.render('Cell')}
                            </span>
                          </td>
                        );
                      } else if (cell.column.id === 'eventName') {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="jobs-event-name">
                            <span>{cell.render('Cell')}</span>
                          </td>
                        );
                      } else if (cell.column.id === 'expirationDate') {
                        return (
                          <td
                            className="datepicker-td"
                            key={shortid.generate()}>
                            {moment(cell.value).tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY')}
                            <span className="edit-icon">
                              <DatePicker
                                locale={currentlanguage}
                                minDate={moment().tz(process.env.REACT_APP_TIMEZONE).utc().toDate()}
                                dateFormat="x"
                                selected={cell.value}
                                onChange={(date) => {
                                  let dates = [];
                                  const jobs = data[i].jobs;
                                  jobs.map((job) => {
                                    job.jobPositions.map((position) =>
                                      dates.push(position.jobDate),
                                    );
                                  });
                                  const minDate = Math.min(...dates);
                                  const currDate = parseInt(
                                    moment(date).tz(process.env.REACT_APP_TIMEZONE).utc().format('x'),
                                  );
                                  if (currDate > minDate) {
                                    toast.error(t('Eventexpirebeforejob'));
                                    return;
                                  }
                                  setEventLoading(true);
                                  const time = moment(date).utc(true).valueOf();
                                  dispatch(
                                    updateEvent({
                                      id: data[i]._id,
                                      payload: {
                                        eventExpirationDate: time,
                                      },
                                      callback: () => setEventLoading(false),
                                    }),
                                  );
                                }}
                                calendarStartDay={1}
                                className="form-control p-0"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </span>
                          </td>
                        );
                      } else {
                        return (
                          <td key={shortid.generate()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      }
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <>
                      {data[i].jobs && data[i].jobs.length ? (
                        <>
                          {data[i].jobs.map((job, jobIndex) => {
                            return (
                              <React.Fragment key={shortid.generate()}>
                                {job.jobPositions && job.jobPositions.length ? (
                                  <>
                                    <tr>
                                      <td>
                                        <span
                                          className="detail-link text-medium"
                                          onClick={() => {
                                            if (currJob === job._id) {
                                              setCurrJob('');
                                            } else {
                                              setCurrJob(job._id);
                                            }
                                            if (expanded === row.id) {
                                              setExpanded({});
                                            } else {
                                              setExpanded({ [row.id]: true });
                                            }
                                          }}>
                                          {job.jobName}
                                        </span>
                                      </td>
                                      <td colSpan={2}>
                                        <p className="mb-0">
                                          {getJobDuration(job)}
                                        </p>
                                      </td>
                                      <td colSpan={4}>
                                        <span className="small-text">
                                          {
                                            checkBookedStaff(job.jobPositions)
                                              .staffNo
                                          }
                                          /{job.jobPositions.length}{' '}
                                          {t('requestOverview.booked')}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className={`row-to-toggle ${
                                        job._id === currJob ? 'fadeInDown' : ''
                                      }`}>
                                      <td
                                        colSpan={4}
                                        style={{ verticalAlign: 'top' }}>
                                        <p className="small-text text-uppercase pb-3">
                                          <strong>
                                            {t('requestOverview.description')}
                                          </strong>
                                        </p>

                                        <div
                                          className="small-text mb-0"
                                          dangerouslySetInnerHTML={{
                                            __html: htmlDecode(
                                              job.jobDescription,
                                            ),
                                          }}
                                        />
                                      </td>
                                      <td
                                        colSpan={2}
                                        style={{ verticalAlign: 'top' }}>
                                        <p className="small-text text-uppercase pb-3">
                                          <strong>
                                            {t(
                                              'requestOverview.staffRequirement',
                                            )}
                                          </strong>
                                        </p>

                                        <div
                                          className="small-text mb-0"
                                          dangerouslySetInnerHTML={{
                                            __html: htmlDecode(
                                              job.jobRequirements,
                                            ),
                                          }}
                                        />
                                      </td>
                                      <td style={{ verticalAlign: 'top' }}>
                                        <p className="small-text text-uppercase pb-3">
                                          <strong>
                                            {t('requestOverview.bonus')}
                                          </strong>
                                        </p>
                                        <p className="small-text mb-0">
                                          <strong>
                                            {job?.jobBonus
                                              ? job?.jobBonus?.replaceAll(
                                                  '%',
                                                  '',
                                                ) + '%'
                                              : ''}
                                          </strong>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr
                                      className={`row-to-toggle sub-table-data ${
                                        job._id === currJob ? 'fadeInDown' : ''
                                      }`}>
                                      <td
                                        colSpan={visibleColumns.length}
                                        style={{ padding: 0 }}>
                                        {renderRowSubComponent({
                                          row,
                                          job,
                                          jobIndex,
                                          currJob,
                                        })}
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  ''
                                )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                      <tr>
                        <td className="text-right last-table-row" colSpan="7">
                          <span
                            className="detail-link text-medium"
                            onClick={() => {
                              setShowNewJobModal({
                                ...showNewJobModal,
                                visible: true,
                                id: data[i].id,
                                eventID: data[i]._id,
                                eventName: data[i].eventName,
                                eventExpirationDate:
                                  data[i].eventExpirationDate,
                              });
                            }}>
                            <strong>{t('addJob')}</strong>
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const RequestOverview = () => {
  const dispatch = useDispatch();
  let ref = React.useRef(null);
  const location = useLocation();
  useEffect(() => {
    let allPositionTotal = 0;
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [expanded, setExpanded] = useState({});
  const [place, setPlace] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [addJobPosition, setAddJobPosition] = useState([]);
  const [currJob, setCurrJob] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [eventLoading, setEventLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [cartCount, setCartCount] = useState('');
  const { t, i18n } = useTranslation();
  const eventsData = useSelector((state) => state.events);
  const allEvents = eventsData.allEvents;
  const { currPage, pageCount } = allEvents;
  let events = allEvents.events;
  events = events.filter((event) => {
    let jobEndDates = [];
    event.jobs.map((job) => {
      job.jobPositions.map((jobPosition, key) => {
        jobEndDates[key] = jobPosition.jobEnd;
      });
    });
    const eventEndDate = jobEndDates.reduce(
      (previousValue, currentValue) =>
        previousValue < currentValue ? currentValue : previousValue,
      jobEndDates[0],
    );

    if (
      eventEndDate && eventEndDate >= moment().utc(true).valueOf()
    ) {
      if (event.jobs && event.jobs.length) {
        event.jobs.map(async (job) => {
          if (job && job.jobPositions && job.jobPositions.length) {
            job.jobPositions.map(async (jobposition) => {
              if (currJob === job._id && jobposition.booked) {
                if (
                  jobposition?.booked &&
                  jobposition?.booked?.employee &&
                  jobposition?.booked?.employee?.images &&
                  jobposition?.booked?.employee?.images.length
                ) {
                  const imageResult =
                    constructGCSImageUrl(
                      jobposition?.booked?.employee?.images[0],
                    ) || defaultAvatar;
                  jobposition.booked.employee.profileImage = imageResult;
                }
              } else if (currJob === job._id && jobposition.staffToBook) {
                if (
                  jobposition?.staffToBook &&
                  jobposition?.staffToBook?.applicant &&
                  jobposition?.staffToBook?.applicant?.images &&
                  jobposition?.staffToBook?.applicant?.images.length
                ) {
                  const imageResult =
                    constructGCSImageUrl(
                      jobposition.staffToBook.applicant.images[0],
                    ) || defaultAvatar;
                  jobposition.staffToBook.applicant.images[0] = imageResult;
                }
              }
              return jobposition;
            });
          }
          return job;
        });
      }
      return event;
    }
  });

  const {
    showNewJobModal,
    setShowNewJobModal,
    showBookingList,
    setShowBookingList,
    showDeleteJobPositionModal,
    setShowDeleteJobPositioModal,
  } = useContext(GlobalContext);
  const [showBrief, setShowBrief] = useState({
    visible: false,
  });
  const [showJobModal, setShowJobModal] = useState({
    visible: false,
    jobId: '',
    jobName: '',
    jobBonus: '0',
    jobPositions: [],
  });
  const [showStaffModal, setShowStaffModal] = useState({
    visible: false,
    data: null,
  });

  React.useEffect(() => {
    if (showStaffModal?.visible && eventsData) {
      const findEvent = eventsData?.allEvents?.events?.find(
        (event) => event?._id === showStaffModal?.data?.job?.eventID,
      );
      const findJob = findEvent?.jobs?.find(
        (job) => job?._id === showStaffModal?.data?.job?._id,
      );
      const findPosition = findJob?.jobPositions?.find(
        (position) => position?._id === showStaffModal?.data?.position?._id,
      );
      setShowStaffModal({
        ...showStaffModal,
        data: {
          ...showStaffModal?.data,
          event: findEvent,
          job: findJob,
          position: findPosition,
        },
      });
    }
    let localCartJobs = [];
    if (events && events.length) {
      events.map((event) => {
        event.jobs.map((job) => {
          job.jobPositions.map((position) => {
            if (
              !position.booked &&
              position.staffToBook &&
              position.staffToBook.applicant
            ) {
              localCartJobs = [
                ...localCartJobs,
                {
                  ...position,
                },
              ];
            }
          });
        });
      });
    }
    const count = localCartJobs.length;
    setCartCount(count);
  }, [eventsData]);

  const stateReducer = useCallback((newState, action, prevState) => {
    // intercept expand toggles and collapse other expanded row(s)
    if (action.type === 'toggleRowExpanded') {
      const prevTokens = Object.keys(prevState.expanded);
      const newTokens = Object.keys(newState.expanded);

      if (newTokens.length > 1) {
        const nextExpanded = {};

        for (const t of newTokens) {
          if (!prevTokens.includes(t)) {
            nextExpanded[t] = true;
          }
        }

        return { ...newState, expanded: nextExpanded };
      }
    }
    return newState;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return `${t('requestOverview.eventId')}`;
        },
        accessor: 'eventId',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('requestOverview.expirationDate')}`;
        },
        accessor: 'expirationDate',
      },
      {
        Header: () => {
          return `${t('requestOverview.staff')}`;
        },
        accessor: 'staff',
      },
      {
        Header: () => {
          return `${t('requestOverview.amountBooked')}`;
        },
        accessor: 'amountBooked',
      },
      {
        Header: () => {
          return `${t('requestOverview.finalAmount')}`;
        },
        accessor: 'finalAmount',
      },
      {
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <strong className="link" {...row.getToggleRowExpandedProps()}>
              {row.isExpanded
                ? `${t('requestOverview.hideDetails')}`
                : `${t('requestOverview.showDetails')}`}
            </strong>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    // if (events && events.length) {
    //   return;
    // }
    setShowBookingList(false);
    dispatch(fetchEvents({ currPage: 1 }, true, false));
  }, []);

  const openpopup = (job, positionID) => {
    setShowDeleteJobPositioModal({
      show: true,
      id: positionID,
      item: job,
    });
  };

  const removeJobPosition = () => {
    if (
      showDeleteJobPositionModal?.show &&
      showDeleteJobPositionModal?.id >= 0
    ) {
      let newJobPositions =
        showDeleteJobPositionModal?.item.jobPositions?.filter((item, id) => {
          return id !== showDeleteJobPositionModal?.id;
        });
      let deleteJobPositions =
        showDeleteJobPositionModal?.item.jobPositions?.filter((item, id) => {
          return id === showDeleteJobPositionModal?.id;
        });

      dispatch(
        updateJob({
          id: showDeleteJobPositionModal?.item?._id,
          payload: {
            jobPositions: newJobPositions,
            deletedPosition: deleteJobPositions ? deleteJobPositions[0] : {},
          },
          callback: () => {
            setEventLoading(false);
            toast.success(`${t('requestOverview.deletedJob')}`);
          },
          failCallback: () => {
            setEventLoading(false);
          },
        }),
      );
      setShowDeleteJobPositioModal({ show: false, id: null, item: null });
    }
  };

  const handleJobPositionSubmit = (e) => {
    e.preventDefault();
    setShowJobModal({
      visible: false,
      jobId: '',
      jobName: '',
      jobBonus: '0',
      jobPositions: [],
    });
    setStartDate(new Date());
    setPlace(null);
    setStartTime(null);
    setEndTime(null);
    setAddJobPosition([
      ...addJobPosition,
      { startDate, place, endTime, startTime },
    ]);
  };

  const handleJobPositionCancel = (e) => {
    e.preventDefault();
    setShowJobModal({ ...showJobModal, visible: false });
    setStartDate(new Date());
    setPlace(null);
    setStartTime(null);
    setEndTime(null);
  };
  const renderbreifings = (url, type) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Briefing-' + moment().tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY') + '.' + `${type}`,
    );
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };
  const getBriefingsUrl = async (briefingspath) => {
    await api
      .post('jobs/fetchbriefingurl', {
        briefingsPath: briefingspath,
      })
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.briefingurl[0].data).buffer]),
        );

        renderbreifings(url, res.data.type);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, job, jobIndex, currJob }) => {
      const renderBriefingsPopover = (briefings, readBriefings) => {
        return (
          <Popover className="brief-popover">
            <Popover.Body>
              <div className="brief-header">
                <h4>{t('requestOverview.briefingsRead')}</h4>
              </div>
              <ul className="small-boxs pb-0" style={{ borderBottom: 'none' }}>
                <li key="1">
                  {briefings && briefings.length && briefings[0] ? (
                    <div
                      className={`small-boxs__item${
                        readBriefings && readBriefings[0] ? ' completed' : ''
                      }`}
                      onClick={() => {
                        getBriefingsUrl(briefings[0]);
                      }}
                      style={{ color: '#41a9c7' }}>
                      1
                    </div>
                  ) : (
                    <div
                      className="small-boxs__item"
                      style={{ cursor: 'default' }}>
                      1
                    </div>
                  )}
                </li>
                <li key="2">
                  {briefings && briefings.length > 1 && briefings[1] ? (
                    <div
                      className={`small-boxs__item${
                        readBriefings && readBriefings[1] ? ' completed' : ''
                      }`}
                      onClick={() => {
                        getBriefingsUrl(briefings[1]);
                      }}
                      style={{ color: '#41a9c7' }}>
                      2
                    </div>
                  ) : (
                    <div
                      className="small-boxs__item"
                      style={{ cursor: 'default' }}>
                      2
                    </div>
                  )}
                </li>
              </ul>
            </Popover.Body>
          </Popover>
        );
      };
      if (job && job.jobPositions && job.jobPositions.length) {
        job.jobPositions.sort(function (a, b) {
          if (
            a.jobStart < b.jobStart ||
            (a.jobStart === b.jobStart && a.jobEnd < b.jobEnd)
          ) {
            return -1;
          }
          if (
            a.jobStart > b.jobStart ||
            (a.jobStart === b.jobStart && a.jobEnd > b.jobEnd)
          ) {
            return 1;
          }
          return 0;
        });
      }
      return (
        <table width="100%">
          <tbody>
            <tr className="sub-table-data sub-table">
              <td colSpan="3">
                <table width="100%">
                  <thead>
                    <tr>
                      <th>
                        <span>{t('requestOverview.position')}</span>
                      </th>
                      <th>
                        <span>{t('placeplaceholder')}</span>
                      </th>
                      <th>
                        <span>{t('dateLabel')}</span>
                      </th>
                      <th>
                        <span>{t('startLabel')}</span>
                      </th>
                      <th>
                        <span>{t('endLabel')}</span>
                      </th>
                      <th>
                        <span>{t('requestOverview.price')}</span>
                      </th>
                      <th>
                        <span>{t('requestOverview.travelCosts')}</span>
                      </th>
                      <th style={{ float: 'right' }}>
                        <span>{t('requestOverview.totalCosts')}</span>
                      </th>
                      <th>
                        <span>{t('requestOverview.staff')}</span>
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {job && job.jobPositions && job.jobPositions.length ? (
                      <>
                        {job?.jobPositions?.map((jobPosition, positionID) => {
                          return (
                            <tr
                              key={shortid.generate()}
                              className="req-overview-tr">
                              <td>
                                <div className="numberList">
                                  {positionID + 1}/{job.jobPositions.length}
                                </div>
                              </td>
                              <td>
                                <span>{jobPosition.jobPlace}</span>
                              </td>
                              <td>
                                <span>
                                  {
                                    moment(jobPosition.jobStart).tz(process.env.REACT_APP_TIMEZONE).format('dddd')
                                  }
                                  <br />
                                  {
                                    moment(jobPosition.jobDate).tz(process.env.REACT_APP_TIMEZONE).format('DD.MM.YYYY')
                                  }
                                </span>
                              </td>
                              <td>
                                <span>
                                  {moment(jobPosition.jobStart).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {moment(jobPosition.jobEnd).tz(process.env.REACT_APP_TIMEZONE).format(
                                    'HH:mm',
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  €{' '}
                                  {!isNaN(jobPosition?.employerData?.jobPrice)
                                    ? Number(
                                        jobPosition?.employerData?.jobPrice,
                                      ).toFixed(2)
                                    : !isNaN(jobPosition?.jobPrice)
                                    ? Number(jobPosition?.jobPrice).toFixed(2)
                                    : 0}
                                </span>
                              </td>
                              <td>
                                €{' '}
                                {Number(
                                  jobPosition?.employerData?.jobTravelCost
                                    ? jobPosition?.employerData?.jobTravelCost
                                    : jobPosition?.jobTravelCost,
                                ).toFixed(2)}
                              </td>
                              <td>
                                <span
                                  className="total-amount"
                                  style={{ float: 'right' }}>
                                  <strong>
                                    {' '}
                                    €{' '}
                                    {Number(
                                      jobPosition?.employerData?.jobTotalCost
                                        ? jobPosition?.employerData
                                            ?.jobTotalCost
                                        : jobPosition?.jobTotalCost
                                        ? jobPosition?.jobTotalCost
                                        : 0,
                                    ).toFixed(2)}
                                  </strong>
                                </span>
                              </td>
                              <td>
                                {job._id === currJob ? (
                                  <BookedStaffImage
                                    position={jobPosition}
                                    job={job}
                                    showStaffModal={showStaffModal}
                                    setShowStaffModal={setShowStaffModal}
                                    setShowBookingList={setShowBookingList}
                                    constructGCSImageUrl={constructGCSImageUrl}
                                  />
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="align-top">
                                {jobPosition.booked ? (
                                  <div className="d-flex flex-column justify-content-center add-briefings-wrapper">
                                    <div className="text-center mb-3">
                                      {t('requestOverview.briefing')}
                                    </div>
                                    <Button
                                      type="button"
                                      className="btn"
                                      label={`${
                                        jobPosition.briefings &&
                                        jobPosition.briefings.length
                                          ? `${t(
                                              'requestOverview.modal.change',
                                            )}`
                                          : `${t('requestOverview.modal.add')}`
                                      }`}
                                      onClick={() => {
                                        setShowBrief({
                                          visible: true,
                                          jobPosition,
                                        });
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={BriefingIcon}
                                      alt="Briefing icon"
                                      style={{
                                        height: 'auto',
                                        maxWidth: 'none',
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="align-top">
                                {jobPosition.booked ? (
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="text-center mb-3">
                                      {t('requestOverview.read')}
                                    </div>
                                    <OverlayTrigger
                                      placement="auto-start"
                                      ref={(r) => (ref = r)}
                                      container={ref.current}
                                      overlay={renderBriefingsPopover(
                                        jobPosition.briefings,
                                        jobPosition.readBriefings,
                                      )}
                                      // show={
                                      //   jobPosition._id === readBriefingOverlayID
                                      // }
                                      trigger="click"
                                      rootClose
                                      // onEnter={() =>
                                      //   setReadBriefingOverlayID(jobPosition._id)
                                      // }
                                      // onExit={() =>
                                      //   setReadBriefingOverlayID(null)
                                      // }
                                      // rootCloseEvent="click"
                                    >
                                      <span
                                        className="read-text"
                                        // onClick={() =>
                                        //   setReadBriefingOverlayID(
                                        //     jobPosition._id,
                                        //   )
                                        // }
                                        // onClick={() =>
                                        //   document.body.classList.toggle(
                                        //     'brief-overlay',
                                        //   )
                                        // }
                                      >
                                        {jobPosition.readBriefings &&
                                        jobPosition.readBriefings.length
                                          ? jobPosition.readBriefings.filter(
                                              (b) => b,
                                            ).length
                                          : 0}
                                        /
                                        {jobPosition.briefings &&
                                        jobPosition.briefings.length
                                          ? jobPosition.briefings.filter(
                                              Boolean,
                                            ).length
                                          : 0}
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                ) : (
                                  <div
                                    className="field-action-icon"
                                    onClick={() => {
                                      if (
                                        jobPosition.applications.length !== 0
                                      ) {
                                        openpopup(job, positionID);
                                      } else {
                                        setEventLoading(true);
                                        const newJobPositions =
                                          job.jobPositions.filter(
                                            (clickedPosition, cpIndex) =>
                                              cpIndex !== positionID,
                                          );
                                        const deletedPosition =
                                          job.jobPositions.filter(
                                            (clickedPosition, cpIndex) =>
                                              cpIndex === positionID,
                                          );
                                        dispatch(
                                          updateJob({
                                            id: job._id,
                                            payload: {
                                              jobPositions: newJobPositions,
                                              deletedPosition:
                                                deletedPosition.length
                                                  ? deletedPosition[0]
                                                  : {},
                                            },
                                            callback: () => {
                                              setEventLoading(false);
                                              toast.success(
                                                `${t(
                                                  'requestOverview.deletedJob',
                                                )}`,
                                              );
                                            },
                                            failCallback: () => {
                                              setEventLoading(false);
                                            },
                                          }),
                                        );
                                      }
                                    }}>
                                    <svg
                                      width="19"
                                      height="22"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                        fill="#41A9C7"
                                        fillRule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td colSpan="11" className="text-right">
                        <span
                          className="detail-link text-medium"
                          onClick={() => {
                            setShowJobModal({
                              visible: true,
                              jobId: job._id,
                              jobName: job.jobName,
                              jobBonus: job.jobBonus,
                              eventExpirationDate: job.eventExpirationDate,
                              jobPositions: job.jobPositions,
                            });
                          }}>
                          <strong>{t('requestOverview.addJobPosition')}</strong>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const renderTable = (events) => {
    events.sort((a, b) => b.eventExpirationDate - a.eventExpirationDate);
    const newEvents = events.map((event, id) => {
      let jobPositions = [];
      if (event.jobs && event.jobs.length) {
        event.jobs.map((job) => {
          jobPositions = [...jobPositions, ...job.jobPositions];
        });
      }

      const bookedStaff = checkBookedStaff(jobPositions);

      return {
        ...event,
        id: event._id,
        eventId: event.eventID,
        eventName: event.eventName,
        expirationDate: event.eventExpirationDate,
        staff: `${bookedStaff.staffNo}/${jobPositions.length} ${t(
          'requestOverview.booked',
        )}`,
        amountBooked: `€ ${Number(bookedStaff.amount).toFixed(2)}`,
        finalAmount: `€ ${Number(
          bookedStaff.amount + bookedStaff.outstandingAmount,
        ).toFixed(2)}`,
        subRows: undefined,
      };
      // }
    });
    // console.log("newEvents",newEvents);
    function sortByEarliestJobDate(eventA, eventB) {
      const earliestJobDateA = Math.min(
        ...eventA.jobs.flatMap((job) =>
          job.jobPositions.map((position) => new Date(position.jobDate)),
        ),
      );
      const earliestJobDateB = Math.min(
        ...eventB.jobs.flatMap((job) =>
          job.jobPositions.map((position) => new Date(position.jobDate)),
        ),
      );
      return earliestJobDateA - earliestJobDateB;
    }
    newEvents.sort(sortByEarliestJobDate);

    return (
      <>
        {eventLoading && <Loader />}
        <Table
          columns={columns}
          data={newEvents}
          showNewJobModal={showNewJobModal}
          setShowNewJobModal={setShowNewJobModal}
          renderRowSubComponent={renderRowSubComponent}
          currJob={currJob}
          setCurrJob={setCurrJob}
          eventLoading={eventLoading}
          setEventLoading={setEventLoading}
          expanded={expanded}
          setExpanded={setExpanded}
          stateReduce={stateReducer}
        />
        {true || currPage >= pageCount ? (
          <></>
        ) : (
          <>
            {loadMoreLoading ? (
              <div className="d-flex align-items-center justify-content-center px-2 py-2">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="load-more">
                <span
                  className="load-more-link"
                  onClick={() => {
                    setLoadMoreLoading(true);
                    dispatch(
                      fetchEvents({
                        currPage: currPage + 1,
                        callback: () => setLoadMoreLoading(false),
                      }),
                    );
                  }}>
                  {t('loadMore')}
                </span>
              </div>
            )}
          </>
        )}
        <p className="small-text placeholder-text ms-0">
          {t('Requestoverview.expirationdatetext')}
          <br />
          {t('Requestoverview.excludingvattext')}
        </p>
      </>
    );
  };

  return (
    <>
      {showStaffModal.visible && (
        <StaffSelection
          showStaffModal={showStaffModal}
          setShowStaffModal={setShowStaffModal}
        />
      )}
      {showNewJobModal && <NewJob />}
      <Brief showBrief={showBrief} setShowBrief={setShowBrief} />
      {showJobModal && (
        <JobPosition
          showJobModal={showJobModal}
          setShowJobModal={setShowJobModal}
          startDate={startDate}
          setStartDate={setStartDate}
          place={place}
          setPlace={setPlace}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          handleJobPositionSubmit={handleJobPositionSubmit}
          handleJobPositionCancel={handleJobPositionCancel}
        />
      )}
      {showDeleteJobPositionModal.show && (
        <DeleteJobModal
          removeJobPosition={removeJobPosition}
          showDeleteJobPositionModal={showDeleteJobPositionModal}
          setShowDeleteJobPositioModal={setShowDeleteJobPositioModal}
        />
      )}
      {showBookingList && (
        <BookingList
          OffcanvasProps={{ placement: 'end', name: 'bookingList' }}
        />
      )}

      <section className="content-section">
        <Head title={t('title.requestOverview')} />
        <Container>
          <div className="box-wrapper form-box-wrapper new-request-block position-relative">
            {eventsData.loading ? (
              <div className="d-flex align-items-center justify-content-center py-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="add-new-request">
                  <span
                    className="cart-icon"
                    onClick={() => setShowBookingList(!showBookingList)}>
                    <CartIcon />
                    {cartCount > 0 && (
                      <div className="cart_box">
                        <span className="cart_list_count">{cartCount}</span>
                      </div>
                    )}
                  </span>
                  <Link
                    to={`/${labelRoles.employer}/new-request`}
                    className="btn">
                    {t('newRequest')}
                  </Link>
                </div>
                {events && events.length ? (
                  <>{renderTable(events)}</>
                ) : (
                  <div className="d-flex  py-4">
                    <h5>{t('event.noEventsFound')}</h5>
                  </div>
                )}
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default RequestOverview;
