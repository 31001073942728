import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useTable, useSortBy } from 'react-table';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Head from '../components/Head';
import apiv2 from '../utils/apiv2';
import { labelRoles } from '../utils/constants';
import { handleError } from '../utils/handleError';
import { convertMsToHM } from '../utils/time';
import 'moment/locale/de';
import { htmlDecode } from '../utils/helper';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );
  const { t, i18n } = useTranslation();

  return (
    <div className="table-responsive">
      <table
        className="table remove-space job-details-table"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'yourSalary') {
                    return (
                      <td {...cell.getCellProps()}>
                        {/*<span className="placeholder-text">
                          {t('event.totalExcl')}
                    </span>*/}
                        <span className="total-amount">
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const getJobHours = (endTime, startTime) => {
  if (startTime > endTime) {
    return endTime + 24 - startTime;
  } else {
    return endTime - startTime;
  }
};

const AppliedJobDetails = (props) => {
  let history = useHistory();
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  moment.locale(localStorage.getItem('i18nextLng') ?? 'de');

  useEffect(() => {
    async function fetchMyAPI() {
      // if (props.location.state && props.location.state.job) {
      //   setJob(props.location.state.job);
      // } else {
      setLoading(true);
      await apiv2
        .get(`/job/${props.match.params.id}`)
        .then((response) => {
          const tempJobs = response.data.data['jobs'];
          let resJob = tempJobs && tempJobs.length ? tempJobs[0] : {};
          const position =
            resJob.jobPositions && resJob.jobPositions.length
              ? resJob.jobPositions[0]
              : null;
          if (position) {
            resJob = {
              ...resJob,
              jobId: resJob._id,
              id: position._id,
              jobPrice: position.jobPrice,
              jobTotalCost: position?.jobTotalCost,
              jobTravelCost: position.jobTravelCost,
              place: position.jobPlace,
              date: position.jobDate,
              startTime: position.jobStart,
              endTime: position.jobEnd,
            };
          }

          setJob(resJob ? resJob : null);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          handleError(e);
        });
    }
    fetchMyAPI();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.match?.params?.eventID,
    props?.match?.params?.id,
    props?.location?.state,
  ]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
      },
      {
        Header: () => {
          return `${t('placeplaceholder')}`;
        },
        accessor: 'place',
      },
      {
        Header: () => {
          return `${t('startLabel')}`;
        },
        accessor: 'startTime',
      },
      {
        Header: () => {
          return `${t('endLabel')}`;
        },
        accessor: 'endTime',
      },
      {
        Header: () => {
          return `${t('profile.price')}`;
        },
        accessor: 'price',
      },
      {
        Header: () => {
          return `${t('requestOverview.travelCosts') + '*'}`;
        },
        accessor: 'travelCosts',
      },
      {
        Header: () => {
          return `${t('event.hours')}`;
        },
        accessor: 'hours',
      },
      {
        Header: () => {
          return `${t('myJob.salary') + '**'}`;
        },
        accessor: 'yourSalary',
      },
    ];
  }, []);

  const data = React.useMemo(() => {
    return [
      // first row data
      {
        date:
          job && job.date ? moment(job.date).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('dddd DD.MM.yyyy') : '0',
        place: job && job.place,
        startTime:
          job && job.startTime ? moment(job.startTime).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('HH:mm') : '0',
        endTime: job && job.endTime ? moment(job.endTime).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('HH:mm') : '0',
        price: `€ ${
          job && job.jobPrice >= 0 ? Number(job.jobPrice).toFixed(2) : 0
        }`,
        travelCosts: `€ ${
          job && job.jobTravelCost >= 0
            ? Number(job.jobTravelCost).toFixed(2)
            : 0
        }`,
        // hours: `${getJobHours(
        //   convertToIntHour(job ? job.endTime : 0),
        //   convertToIntHour(job ? job.startTime : 0),
        // )} h`,
        hours: `${convertMsToHM(
          (job ? job.endTime : 0) - (job ? job.startTime : 0),
        )} `,
        yourSalary: `€ ${job && Number(job?.jobTotalCost).toFixed(2)}`,
        // yourSalary: `€ ${getSum({
        //   price: job && job.jobPrice >= 0 ? job.jobPrice : 0,
        //   travelCosts: job && job.jobTravelCost >= 0 ? job.jobTravelCost : 0,
        // })}`,
      },
    ];
  }, [job]);

  return (
    <section className="content-section">
      <Head title={t('title.jobDetails')} />
      <Container>
        <div className="box-wrapper">
          <div className="form-back-wrapper">
            <div
              className="form-back"
              onClick={() => {
                history.push({
                  pathname: `/${labelRoles.employee}/my-jobs`,
                });
              }}></div>
            <div className="contact-btn-wrapper">
              <p>
                {t('needHelp')} <br /> {t('contact')}
              </p>
              <Link to="/contact" className="btn">
                {t('home.contact')}
              </Link>
            </div>
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center py-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <>
              {!job ? (
                <></>
              ) : (
                <>
                  <h4>
                    {t('requestOverview.eventName')}: {job.eventName}
                  </h4>
                  <h3 className="headline-with-line">{job.jobName}</h3>
                  <Row>
                    <Col lg="6">
                      <p className="small-text text-uppercase text-semibold pb-3">
                        {t('requestOverview.description')}
                      </p>
                      <p className="small-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlDecode(job.jobDescription),
                          }}
                        />
                      </p>
                    </Col>
                    <Col lg="6">
                      <p className="small-text text-uppercase text-semibold pb-3">
                        {t('requestOverview.staffRequirement')}
                      </p>
                      <p className="small-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlDecode(job.jobRequirements),
                          }}
                        />
                      </p>
                    </Col>
                  </Row>
                  <h3 className="headline-with-line">
                    {t('Operational_data')}
                  </h3>
                  <Table columns={columns} data={data} />
                  {(props?.location?.state?.fromMyJob === true &&
                    job?.jobPositions?.[0]?.applications?.[0]?.remarks ===
                      undefined) ||
                  job?.jobPositions?.[0]?.applications?.[0]?.remarks?.length <=
                    0 ? (
                    ''
                  ) : (
                    <div className="form-wrapper border-bottom remarksForEmployee">
                      <h3 className="m-0">
                        {t('jobWallStaff.remarksForEmployee')}
                      </h3>
                      <Row>
                        <Col>
                          {props?.location?.state?.fromMyJob === true && (
                            <p>
                              {
                                job?.jobPositions?.[0]?.applications?.[0]
                                  ?.remarks
                              }
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                  <p className="small-text placeholder-text ms-0">
                    {t('jobWallStaff.travelcoststext')}
                    <br />*{t('jobWallStaff.textOne')}
                    <br /> {t('jobWallStaff.otherdetail')}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default AppliedJobDetails;
