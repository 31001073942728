import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  updateJobPosition,
  checkEmployeestatus,
  checkRejectedEmployeestatus,
  handleOverlappingJobs,
} from '../../actions/jobAction';
// import { fetchEvents } from '../../actions/eventAction';
import Button from '../Core/Button';
// import Loader from '../Core/Loader';
import { labelRoles } from '../../utils/constants';
import { toast } from 'react-toastify';
import GlobalContext from '../../context/GlobalContext';
import i18n from '../../i18n';

const displaycartitems = ({ cartJobs, employee, startDate, endDate }) => {
  let flag = false;
  if (cartJobs && cartJobs.length) {
    cartJobs.some((cartJob) => {
      if (
        cartJob.staffToBook &&
        cartJob.staffToBook.applicant &&
        cartJob.staffToBook.applicant.blockedJobs &&
        cartJob.staffToBook.applicant.blockedJobs.length
      ) {
        if (cartJob.staffToBook.applicant === employee) {
          return (flag =
            endDate > cartJob.jobStart && cartJob.jobEnd > startDate);
        }
      }
    });
    if (flag === true) {
      // setAddToListLoading(false);
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const ManageInfoModal = ({
  staff,
  job,
  applicant_id,
  jobPositionId,
  position,
  fetchStaffDetails,
  setShowInfoModal,
  loading,
  setLoading,
  // setLoadingAction,
}) => {
  const [disableRejectBtn, setDisableRejectBtn] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  // const openInfoModal = () => setShowInfoModal(true);
  const { t } = useTranslation();
  const { setShowBookingList } = useContext(GlobalContext);
  const [cartJobs, setCartJobs] = useState([]);
  // const [loading, setLoading] = useState(false);
  const eventsData = useSelector((state) => state.events);
  const userData = useSelector((state) => state.user);
  const loggedinuser = userData.data.role;
  const { listLoadingExtern, setListLoadingExtern } = useContext(GlobalContext);
  useEffect(() => {
    let localCartJobs = [];
    const events = staff.appliedJobs;
    if (events && events.length) {
      events.forEach((event) => {
        if (
          !position.booked &&
          position.staffToBook &&
          position.staffToBook.applicant
        ) {
          localCartJobs = [
            ...localCartJobs,
            {
              ...position,
              jobName: event.jobName,
              eventName: event.eventName,
              eventID: event.eventID,
              companyName: event.companyName,
            },
          ];
        }
      });
    }
    setCartJobs([...localCartJobs]);
  }, [position, staff.appliedJobs]);

  return (
    <div className="form-bottom justify-content-end">
      {/*{loading && (
        <div
          style={{
            minHeight: '50vh',
          }}>
          <Loader />
        </div>
        )}*/}
      <Button
        label={t('profile.btnReject')}
        className="btn btn--outline"
        onClick={() => {
          setDisableRejectBtn(true);
          setLoading(true);
          let newPosition = position;
          delete newPosition.applications;
          delete newPosition._id;
          if (newPosition?.readyToBook?.applicant === applicant_id) {
            delete newPosition.readyToBook;
          }
          if (newPosition?.staffToBook?.applicant === applicant_id) {
            delete newPosition.staffToBook;
          }
          newPosition.rejectedApplicants = [
            ...newPosition.rejectedApplicants,
            { applicant: applicant_id },
          ];
          dispatch(
            updateJobPosition({
              id: jobPositionId,
              payload: newPosition,
              callback: () => {
                setDisableRejectBtn(false);
                setLoading(false);
                window.location.reload();
              },
              failCallback: () => true,
            }),
          );
        }}
        disabled={
          disableRejectBtn ||
          position?.staffToBook?.applicant ||
          position?.readyToBook?.applicant
        }
      />
      <Button
        type="submit"
        label={
          position?.staffToBook?.applicant === applicant_id
            ? t('requestOverview.addedToList')
            : t('staff.addToList')
        }
        className="btn"
        disabled={
          position?.staffToBook?.applicant
            ? true
            : position?.readyToBook?.applicant &&
              position?.readyToBook?.applicant !== applicant_id
            ? true
            : false
        }
        onClick={() => {
          setLoading(true);
          setListLoadingExtern(true);
          let emp = {
            location: staff.location,
            images: staff.images,
            foreignLanguages: staff.foreignLanguages,
            _id: staff._id,
            gender: staff.gender,
            hairColor: staff.hairColor,
            motherTongue: staff.motherTongue,
            size: staff.size,
            driversLicense: staff.driversLicense,
            carAvailable: staff.carAvailable,
            name: staff.name,
            blockedJobs: staff.blockedJobs,
          };
          dispatch(
            checkEmployeestatus({
              jobId: position._id,
              employee: emp,
              jobStart: job.position.jobStart,
              jobEnd: job.position.jobEnd,
              callback: () => {
                let newPosition = {
                  ...position,
                  inAddedList: true,
                };
                delete newPosition.applications;
                delete newPosition._id;
                newPosition.staffToBook = {
                  applicant: emp._id,
                };
                newPosition.readyToBook = {
                  applicant: emp._id,
                };
                dispatch(
                  updateJobPosition({
                    id: position._id,
                    payload: newPosition,
                    callback: async () => {
                      await (fetchStaffDetails && fetchStaffDetails());
                      setLoading(false);
                      setShowBookingList(true);
                    },
                    failCallback: () => {
                      setLoading(false);
                    },
                    staff: applicant_id,
                  }),
                );
              },
              failCallback: () => {
                setLoading(false);
              },
            }),
          );

          // setLoadingAction(false);
        }}
      />
      <Button
        type="submit"
        label={t('profile.btnBookNow')}
        onClick={() => {
          setLoading(true);
          dispatch(
            checkEmployeestatus({
              jobId: position._id,
              employee: staff,
              jobStart: job.position.jobStart,
              jobEnd: job.position.jobEnd,
              callback: () => {
                dispatch(
                  checkRejectedEmployeestatus({
                    jobId: position._id,
                    employee: staff,
                    callback: () => {
                      dispatch(
                        handleOverlappingJobs({
                          id: position._id,
                          employeeId: staff._id,
                          payload: position,
                          callback: async () => {
                            if (loggedinuser === 'superuser') {
                              history.push({
                                pathname: `/${labelRoles.superuser}/request-pay`,
                                state: {
                                  jobs: [
                                    {
                                      ...position,
                                      eventName: job.event,
                                      eventID: job.eventID,
                                      eventId: job?.eventId,
                                      jobName: job?.jobName,
                                    },
                                  ],
                                  // job: job,
                                  employee: staff,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/${labelRoles.employer}/request-pay`,
                                state: {
                                  jobs: [
                                    {
                                      ...position,
                                      eventName: job.event,
                                      eventID: job.eventID,
                                      eventId: job?.eventId,
                                      jobName: job?.jobName,
                                    },
                                  ],
                                  // job: job,
                                  employee: staff,
                                },
                              });
                            }
                            setLoading(false);
                          },
                          failCallback: () => {
                            setLoading(false);
                          },
                        }),
                      );
                    },
                    failCallback: async () => {
                      await (fetchStaffDetails && fetchStaffDetails());
                      setLoading(false);
                    },
                  }),
                );
              },
              failCallback: () => {
                setLoading(false);
              },
            }),
          );
        }}
        className="btn"
      />
    </div>
  );
};

export default ManageInfoModal;
