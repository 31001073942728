import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, OverlayTrigger, Row, Spinner } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import * as Yup from 'yup';
import api from '../../utils/api';
import { handleError } from '../../utils/handleError';
import { manageStartEndTime } from '../../utils/time';
import Button from '../Core/Button';
import CustomSelect from '../Core/Select';
import { EndInfo, RenderEndWarning } from '../CreateJob/CreateJobPosition';
import SearchLocationInput from '../SearchLocationInput';
import { useSelector, useDispatch } from 'react-redux';
import StartNowModal from './StartNowModal';
import { getTimeDifferenceHours, getTimeDifferent } from '../../utils/helper';

const quantityOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
];

const initialJob = {
  quantity: quantityOptions[0],
  job: '',
  hourlyRate: 0,
  place: {
    value: '',
    address: '',
    coordinates: '',
  },
  date: '',
  start: '',
  end: '',
  jobTrvelCost: 0,
  jobPrice: 0,
  jobTotalCost: 0,
  employerData: {
    jobPrice: 0,
    jobTravelCost: 0,
    jobTotalCost: 0,
    hourlyRate: 0,
  },
};
registerLocale('de', de);
registerLocale('en', enUS);

const AddJob = () => {
  const formRef = useRef(null);
  const history = useHistory();

  const [resEmployees, setResEmployees] = useState(
    history.location.state && history.location.state.employees
      ? history.location.state.employees
      : [],
  );
  const [historyState, setHistoryState] = useState(true);
  const [jobLoading, setJobLoading] = useState(true);
  const [total, settotal] = useState(0);
  const [jobPositions, setJobPositions] = useState([]);
  const [staffFound, setStaffFound] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const settingsData = useSelector((state) => state?.settings);
  const { settingData } = settingsData;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [showStartNowModal, setshowStartNowModal] = useState(false);
  const allZipCodes = useSelector((state) => state?.zipcode?.zipcodeList);

  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );

  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        if (
          settingData &&
          settingData.staffTypes &&
          settingData.staffTypes.length
        ) {
          setJobLoading(false);
          const filteredJobs = settingData.staffTypes.map((job) => ({
            label: job.name,
            value: job.name,
          }));
          setJobPositions(filteredJobs);
        }
        // let response = await api.get('/global-settings');
        // let resJobs = response.data.settings[0].staffTypes;
      } catch (e) {
        setJobLoading(false);
        handleError(e);
      }
    };

    fetchJobs();
  }, [settingData]);

  useEffect(() => {
    let NewApplications;
    const getimages = async () => {
      NewApplications = await Promise.all(
        resEmployees.map(async (employee) => {
          if (employee && employee.data && employee.data.length) {
            const newemployees = await Promise.all(
              employee.data.map(async (emp) => {
                // console.log('emp', emp);
                if (emp.images && emp.images.length) {
                  if (
                    !emp.images[0].includes('https://storage.googleapis.com') &&
                    (emp.images[0].startsWith('employee-') ||
                      emp.images[0].startsWith('user-'))
                  ) {
                    const image = emp?.images[0];
                    const result = await api
                      .post('users/staffImages', {
                        image,
                      })
                      .catch(() => {});
                    emp.images[0] = result.data.image;
                  }
                }
                return emp;
              }),
            );
            employee.data = newemployees;
          }
          return employee;
        }),
      );

      setResEmployees(NewApplications);
      setLoading(false);
    };

    if (resEmployees && resEmployees.length) {
      setLoading(true);
      getimages();
    }
  }, [searchLoading]);
  const getPositions = () => {
    return staffFound;
  };

  const checkJobsValue = () => {
    const jobs = formRef && formRef.current ? formRef.current.values.jobs : [];
    const jobsLength = jobs ? jobs.length : 0;
    const disableStyling = {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      opacity: '0.55',
    };
    const enableStyling = {
      pointerEvents: 'auto',
      cursor: 'pointer',
      opacity: '1',
    };
    if (jobsLength) {
      if (jobsLength > 1) {
        const job = jobs[jobsLength - 1];
        if (job.date && job.end && job.job && job.place.value && job.start) {
          return enableStyling;
        } else {
          return disableStyling;
        }
      } else if (jobsLength === 1) {
        const job = jobs[0];
        if (job.date && job.end && job.job && job.place.value && job.start) {
          return enableStyling;
        } else {
          return disableStyling;
        }
      } else {
        return disableStyling;
      }
    } else {
      return disableStyling;
    }
  };
  return (
    <div className="box-wrapper form-add-job">
      <p className="large">{t('home.infoContent')}</p>
      <Formik
        innerRef={formRef}
        initialValues={{
          jobs:
            historyState &&
            history.location.state &&
            history.location.state.jobs
              ? history.location.state.jobs
              : [{ ...initialJob }],
        }}
        validationSchema={Yup.object({
          jobs: Yup.array().of(
            Yup.object().shape({
              quantity: Yup.object().shape({
                label: Yup.string().required(t('quantity')),
                value: Yup.string().required(t('quantity')),
              }),
              job: Yup.object().shape({
                label: Yup.string().required(t('Job')),
                value: Yup.string().required(t('Job')),
              }),
              place: Yup.object().shape({
                address: Yup.string().required(t('place')),
              }),
              date: Yup.string()
                .typeError(t('date.type'))
                .required(t('date.required'))
                .min(new Date(Date.now() - 864e5), t('date.minDate')),
              start: Yup.date()
                .test('start', t('startTime.addJob'), (value, fields) => {
                  if (!fields.parent.date) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .required(t('startTime.required'))
                .test('start', t('startTime.duration'), (value, fields) => {
                  return (
                    manageStartEndTime(
                      moment(value).format('x') * 1,
                      fields.parent.date,
                    ) > new Date().valueOf()
                  );
                }),
              start: Yup.date()
                .test('start', t('startTime.addJob'), (value, fields) => {
                  if (!fields.parent.date) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .required(t('startTime.required'))
                .test('start', t('startTime.duration'), (value, fields) => {
                  return (
                    manageStartEndTime(
                      moment(value).format('x') * 1,
                      fields.parent.date,
                    ) > new Date().valueOf()
                  );
                }),
              end: Yup.date()
                .required(t('endTime.required'))
                .test('same', t('startendtimesameerror'), (value, fields) => {
                  let startDate = parseInt(
                    moment(fields.parent.start).format('x'),
                  );
                  let endDate = parseInt(moment(fields.parent.end).format('x'));
                  if (
                    moment(startDate).format('HH:mm') ===
                    moment(endDate).format('HH:mm')
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .test('end', t('endTime.duration'), (value, fields) => {
                  const hoursDiff = getTimeDifferent(
                    fields.parent.start,
                    fields.parent.end,
                  );
                  return parseFloat(hoursDiff.toFixed(2)) <= 12;
                }),
            }),
          ),
        })}
        onSubmit={async (values) => {
          setSearchLoading(true);
          let staff = 0;
          let totalcost = 0;
          const validValues = values.jobs.map((job, jobID) => ({
            ...job,
          }));
          let response = [];
          try {
            await Promise.all(
              validValues.map(async (validValue, validId) => {
                let hours = getTimeDifferent(validValue.start, validValue.end);
                hours = hours < 4 ? 4 : hours;
                const qty = parseFloat(validValue.quantity.value);
                const jobtotal = Number(
                  qty * validValue.employerData.hourlyRate * hours +
                    qty * validValue.employerData.jobTravelCost,
                );
                totalcost += jobtotal;
                const endTime =
                  parseInt(moment(validValue.end).format('x')) <
                  parseInt(moment(validValue.start).format('x'))
                    ? parseInt(moment(validValue.end).format('x')) + 86400000
                    : parseInt(moment(validValue.end).format('x'));

                let searchRegion = [
                  `${String(validValue?.place?.address)?.toLowerCase()}`,
                  `${String(validValue?.place?.state)?.toLowerCase()}`,
                ];
                const employees = await api.post(`/search-staff`, {
                  preferedJobs: `${validValue.job.value}`,
                  preferedJobRegion: searchRegion,
                  jobStart: manageStartEndTime(
                    validValue.start,
                    validValue.date,
                  ),
                  jobEnd: manageStartEndTime(endTime, validValue.date),
                });
                response = [
                  {
                    name: validValue.job.value,
                    data: employees.data.data.employees,
                  },
                  ...response,
                ];
                staff += employees.data.results;
              }),
            );
            setStaffFound(staff);
            settotal(totalcost.toFixed(2));
            setResEmployees([...response]);
            setSearchLoading(false);
          } catch (error) {
            handleError(error);
            setSearchLoading(false);
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          resetForm,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="form add-job-form">
            <FieldArray
              name="jobs"
              render={(arrayHelpers) => {
                const handleFieldChange = ({ id, name, value }) => {
                  const newValues = values.jobs.map((v, vid) => {
                    if (vid === id) {
                      if (name === 'job') {
                        const currentStaffType = staffTypes?.find(
                          (s) => s.name === value.value,
                        );
                        v.hourlyRate = currentStaffType?.pricePerHour;
                        v.employerData.hourlyRate =
                          currentStaffType?.effectivePricePerHour;
                      }
                      return {
                        ...v,
                        [name]: value,
                      };
                    } else {
                      return { ...v };
                    }
                  });
                  setFieldValue('jobs', newValues);
                };
                return (
                  <>
                    {values?.jobs && values?.jobs.length
                      ? values?.jobs.map((job, index) => (
                          <React.Fragment key={index}>
                            <div key={index} className="add-job-container">
                              <Row>
                                <Col xxl="4">
                                  <Row>
                                    <Col sm="6" md="3" xxl="4">
                                      <CustomSelect
                                        name="quantity"
                                        label={t('quantityLabel')}
                                        options={quantityOptions}
                                        handleChange={(value) => {
                                          handleFieldChange({
                                            id: index,
                                            name: 'quantity',
                                            value: value,
                                          });
                                        }}
                                        touched={
                                          touched.jobs &&
                                          touched.jobs[index] &&
                                          touched.jobs[index].quantity
                                        }
                                        errors={
                                          errors.jobs &&
                                          errors.jobs[index] &&
                                          errors.jobs[index].quantity &&
                                          errors.jobs[index].quantity.label
                                        }
                                        value={
                                          values.jobs &&
                                          values.jobs[index] &&
                                          values.jobs[index].quantity
                                        }
                                        classNamePrefix="quantity-select c-select"
                                      />
                                    </Col>
                                    <Col sm="6" md="9" xxl="8">
                                      <CustomSelect
                                        name="job"
                                        label={t('JobLabel') + '*'}
                                        placeholder={t('jobType')}
                                        options={jobPositions}
                                        isLoading={jobLoading}
                                        handleChange={(value) => {
                                          handleFieldChange({
                                            id: index,
                                            name: 'job',
                                            value: value,
                                          });
                                        }}
                                        touched={
                                          touched.jobs &&
                                          touched.jobs[index] &&
                                          touched.jobs[index].job
                                        }
                                        errors={
                                          errors.jobs &&
                                          errors.jobs[index] &&
                                          errors.jobs[index].job &&
                                          errors.jobs[index].job.label
                                        }
                                        value={
                                          values.jobs &&
                                          values.jobs[index] &&
                                          values.jobs[index].job
                                        }
                                        classNamePrefix="job-select c-select"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" xl="3" xxl="3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {t('placeLabel') + '*'}
                                    </label>
                                    <Field
                                      name={`jobs.${index}.place`}
                                      allZipCodes={allZipCodes}
                                      travelCostFieldName={`jobs.${index}.jobTravelCost`}
                                      effectiveTravelCostFieldName={`jobs.${index}.employerData.jobTravelCost`}
                                      component={SearchLocationInput}
                                      index={index}></Field>
                                    <div className="form-errors">
                                      <ErrorMessage
                                        name={`jobs.${index}.place.address`}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6" xl="3" xxl="2">
                                  <div className="form-group date-picker-group">
                                    <label className="form-label">
                                      {t('dateLabel') + '*'}
                                    </label>
                                    <DatePicker
                                      locale={currentlanguage}
                                      minDate={moment().toDate()}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                      selected={
                                        values.jobs &&
                                        values.jobs[index] &&
                                        values.jobs[index].date
                                      }
                                      onChange={(date) => {
                                        const time = new Date(date).valueOf();
                                        handleFieldChange({
                                          id: index,
                                          name: 'date',
                                          value: time,
                                        });
                                      }}
                                      calendarStartDay={1}
                                      dateFormat="dd.MM.yyyy"
                                      className="form-control"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                    {touched.jobs &&
                                      touched.jobs[index] &&
                                      touched.jobs[index].date &&
                                      errors.jobs &&
                                      errors.jobs[index] &&
                                      errors.jobs[index].date && (
                                        <div className="form-errors">
                                          {errors.jobs[index].date}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col xl="6" xxl="3">
                                  <Row>
                                    <Col>
                                      <div className="form-group time-picker-group">
                                        <label className="form-label">
                                          {t('startLabel') + '*'}
                                        </label>
                                        <DatePicker
                                          locale={currentlanguage}
                                          selected={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].start
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                          onChange={(date) => {
                                            handleFieldChange({
                                              id: index,
                                              name: 'start',
                                              value: date,
                                            });
                                          }}
                                          showTimeSelectOnly
                                          timeCaption={t('startTimeLabel')}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          dateFormat="HH:mm"
                                          className="form-control"
                                          placeholderText="00:00"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                        />
                                        {touched.jobs &&
                                          touched.jobs[index] &&
                                          touched.jobs[index].start &&
                                          errors.jobs &&
                                          errors.jobs[index] &&
                                          errors.jobs[index].start && (
                                            <div className="form-errors">
                                              {errors.jobs[index].start}
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="form-group time-picker-group">
                                        <label className="form-label">
                                          {t('endLabel') + '*'}
                                          <>
                                            {values.jobs &&
                                              values.jobs[index] &&
                                              values.jobs[index].end &&
                                              values.jobs[index].start && (
                                                <RenderEndWarning
                                                  start={
                                                    values.jobs[index].start
                                                  }
                                                  end={values.jobs[index].end}
                                                />
                                              )}
                                          </>
                                        </label>
                                        <DatePicker
                                          locale={currentlanguage}
                                          selected={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].end
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                          onChange={(date) => {
                                            handleFieldChange({
                                              id: index,
                                              name: 'end',
                                              value: date,
                                            });
                                          }}
                                          showTimeSelectOnly
                                          timeCaption={t('endTimeLabel')}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          dateFormat="HH:mm"
                                          placeholderText="00:00"
                                          className="form-control"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                        />
                                        {touched.jobs &&
                                          touched.jobs[index] &&
                                          touched.jobs[index].end &&
                                          errors.jobs &&
                                          errors.jobs[index] &&
                                          errors.jobs[index].end && (
                                            <div className="form-errors">
                                              {errors.jobs[index].end}
                                              {errors.jobs[index].end.includes(
                                                '10h',
                                              ) && (
                                                <OverlayTrigger
                                                  placement="auto-start"
                                                  delay={{
                                                    show: 100,
                                                    hide: 400,
                                                  }}
                                                  overlay={EndInfo}>
                                                  <svg
                                                    width="22"
                                                    height="23"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="popover-icon">
                                                    <g
                                                      transform="translate(0 .5)"
                                                      fill="none"
                                                      fillRule="evenodd">
                                                      <circle
                                                        fill="#41A9C7"
                                                        cx="11"
                                                        cy="11"
                                                        r="11"
                                                      />
                                                      <path
                                                        d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                                                        fill="#FFF"
                                                        fillRule="nonzero"
                                                      />
                                                    </g>
                                                  </svg>
                                                </OverlayTrigger>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <div className="delete-fields">
                                <div
                                  className="form-group"
                                  style={{
                                    cursor: 'pointer',
                                    pointerEvents: `${
                                      resEmployees.length ||
                                      values.jobs.length === 1
                                        ? 'none'
                                        : 'auto'
                                    }`,
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}>
                                  <label className="form-label">
                                    {t('delete')}
                                  </label>
                                  <span className="delete-btn">
                                    <svg viewBox="0 0 19 22">
                                      <path
                                        fill="#41A9C7"
                                        fillRule="evenodd"
                                        d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 01-.841-.849V7.605c0-.47.376-.849.84-.849zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 01-.841-.849V7.605c0-.47.375-.849.84-.849zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849zm2.662 13.264a.282.282 0 01-.28.282H3.784a.286.286 0 01-.28-.282V4.81h11.911v15.21zM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 00-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 001.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 00-.84-.848h-4.558z"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))
                      : null}
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          animation="border"
                          role="status"
                          className="text-center">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      </div>
                    ) : resEmployees?.length ? (
                      <>
                        <div className="form-bottom">
                          <Button
                            type="button"
                            label={t('clear')}
                            className="btn btn--outline"
                            onClick={(e) => {
                              setHistoryState(false);
                              arrayHelpers.form.setFieldValue('jobs', [
                                initialJob,
                              ]);
                              resetForm();
                              setResEmployees([]);
                            }}
                          />
                          <Button
                            type="submit"
                            label={t('home.btnSearch')}
                            disabled={searchLoading}
                            className="btn primary-btn"
                          />
                          <div className="w-100 text-end mt-3">
                            <Button
                              type="button"
                              label={t('createEventandBookStaff')}
                              className="btn primary-btn"
                              onClick={(event) => {
                                if (user?.data?.role === 'employer') {
                                  history.push(`organization/new-request`);
                                } else if (user?.data?.role === 'superuser') {
                                  history.push(`superuser/eventslist`);
                                } else {
                                  setshowStartNowModal(true);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="selected-job-tax">
                          <p className="placeholder-text ms-0"></p>
                          <div className="selected-job-tax-value">
                            <p className="placeholder-text">
                              {t('home.estimatedTotal')}
                            </p>
                            <span className="total-amount">€ {total}</span>
                          </div>
                        </div>
                        <div className="sample-staff-block">
                          <p className="small-text">
                            {t('wefound')} {getPositions()} {t('noofpersons')}
                          </p>
                          <Row>
                            {resEmployees.map((employee, id) => {
                              return (
                                <Col md="6" key={shortid.generate()}>
                                  <h5 className="headline">{employee.name}</h5>
                                  <ul className="applicant-lists">
                                    {employee.data && employee.data.length ? (
                                      <>
                                        {employee.data.map((e) => {
                                          return (
                                            <li key={shortid.generate()}>
                                              <div>
                                                {e?.images &&
                                                e?.images?.length &&
                                                e?.images?.[0]?.trim() ? (
                                                  <img
                                                    src={
                                                      e?.images?.[0].includes(
                                                        'https://storage.googleapis.com',
                                                      )
                                                        ? e?.images?.[0]
                                                        : !e.images[0].startsWith(
                                                            'employee-',
                                                          ) &&
                                                          !e.images[0].startsWith(
                                                            'user-',
                                                          )
                                                        ? `${e?.images?.[0]}`
                                                        : ''
                                                    }
                                                    loading="lazy"
                                                    alt={'staff.profile'}
                                                  />
                                                ) : (
                                                  <img
                                                    src="/images/blank-profile-picture.png"
                                                    alt={''}
                                                    loading="lazy"
                                                  />
                                                )}

                                                {e.gender === 'male' ? (
                                                  <span className="staff-gender male"></span>
                                                ) : e.gender === 'female' ? (
                                                  <span className="staff-gender female"></span>
                                                ) : (
                                                  <span className="staff-gender other"></span>
                                                )}
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {t('nofound1')} {employee.name}{' '}
                                        {t('nofound2')}
                                      </>
                                    )}
                                  </ul>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="add-another-job">
                          <span
                            style={{ ...checkJobsValue() }}
                            className="link"
                            onClick={() => {
                              arrayHelpers.push({
                                ...initialJob,
                              });
                            }}>
                            {t('home.linkJob')}
                          </span>
                        </div>
                        {values.jobs && values.jobs.length ? (
                          <div className="form-bottom">
                            <Button
                              type="submit"
                              label={t('startSearch')}
                              disabled={searchLoading}
                              className="btn primary-btn"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                );
              }}
            />
          </Form>
        )}
      </Formik>
      <StartNowModal
        show={showStartNowModal}
        onHide={() => setshowStartNowModal(false)}
      />
    </div>
  );
};

export default AddJob;
