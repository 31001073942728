import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import Button from '../Core/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { manageStartEndTime } from '../../utils/time';
// import api from '../../utils/api';
// import { handleError } from '../../utils/handleError';
import { updateJob } from '../../actions/jobAction';
import JobPositionForm, { initialJobPosition } from '../JobPositionForm';
import Loader from '../../components/Core/Loader';
import {
  getTimeDifferent,
  getTimeWithOffset,
  endTimeEndsNextDay,
} from '../../utils/helper';

const JobPosition = ({
  showJobModal,
  setShowJobModal,
  startDate,
  setStartDate,
  place,
  setPlace,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  handleJobPositionSubmit,
  handleJobPositionCancel,
}) => {
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // fetchPlaces();
  }, []);
  return (
    <Modal
      show={showJobModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={() => setShowJobModal({ ...showJobModal, visible: false })}
      className="add-job-login-modal contract-modal">
      {loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('requestOverview.addJobPosition')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            jobs: [{ ...initialJobPosition }],
          }}
          validationSchema={Yup.object({
            jobs: Yup.array().of(
              Yup.object().shape({
                place: Yup.object().shape({
                  address: Yup.string().required(t('place')),
                }),
                date: Yup.date()
                  .typeError(t('date.type'))
                  .required(t('date.required'))
                  .test('date', t('jobdatevalidation'), (value, fields) => {
                    const startOfJobDay = moment(fields?.parent?.date)
                      .utc(true)
                      .valueOf();
                    const eventExpirationDate = parseInt(
                      moment(showJobModal.eventExpirationDate).format('x'),
                    );
                    if (
                      eventExpirationDate &&
                      eventExpirationDate > startOfJobDay
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }),
                start: Yup.date()
                  .test('start', t('startTime.addJob'), (value, fields) => {
                    if (!fields.parent.date) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .required(t('startTime.required'))
                  .test('start', t('startTime.duration'), (value, fields) => {
                    return (
                      manageStartEndTime(
                        moment(value)
                          .tz(process.env.REACT_APP_TIMEZONE)
                          .utc()
                          .format('x') * 1,
                        fields.parent.date,
                      ) > new Date().valueOf()
                    );
                  }),
                end: Yup.date()
                  .required(t('endTime.required'))
                  .test('same', t('startendtimesameerror'), (value, fields) => {
                    let startDate = parseInt(
                      moment(fields.parent.start)
                        .tz(process.env.REACT_APP_TIMEZONE)
                        .utc()
                        .format('x'),
                    );
                    let endDate = parseInt(
                      moment(fields.parent.end)
                        .tz(process.env.REACT_APP_TIMEZONE)
                        .utc()
                        .format('x'),
                    );
                    if (
                      moment(startDate)
                        .tz(process.env.REACT_APP_TIMEZONE)
                        .utc()
                        .format('HH:mm') ===
                      moment(endDate)
                        .tz(process.env.REACT_APP_TIMEZONE)
                        .utc()
                        .format('HH:mm')
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .test('end', t('endTime.duration'), (value, fields) => {
                    const hoursDiff = getTimeDifferent(
                      fields.parent.start,
                      fields.parent.end,
                    );
                    return parseFloat(hoursDiff.toFixed(2)) <= 12;
                  }),
                // .required(t('endTime.required')),
              }),
            ),
          })}
          onSubmit={async (values) => {
            // debugger;
            const newValues = values.jobs.map((job) => {
              const jobPrice = parseFloat(Number(job?.price).toFixed(2)) || 0;
              const jobTravelCost =
                parseFloat(Number(job?.travelCosts).toFixed(2)) || 0;
              const employerDataJobPrice =
                parseFloat(Number(job?.employerData?.jobPrice).toFixed(2)) || 0;
              const employerDataJobTravelCost =
                parseFloat(
                  Number(job?.employerData?.jobTravelCost).toFixed(2),
                ) || 0;

              const locationDate = moment(job.date)
                .utc(true)
                .tz(process.env.REACT_APP_TIMEZONE)
                .valueOf();

              const newStartTime = getTimeWithOffset(
                job.start,
                locationDate,
                process.env.REACT_APP_TIMEZONE,
              );
              const newEndTime = getTimeWithOffset(
                job.end,
                locationDate,
                process.env.REACT_APP_TIMEZONE,
              );

              const adjustEndTime = endTimeEndsNextDay(
                newStartTime,
                newEndTime,
              );

              return {
                jobStart: newStartTime,
                jobEnd: adjustEndTime,
                jobDate: locationDate,
                jobPlace: job.place.value,
                jobPlaceAddress: job.place,
                jobPrice,
                jobTravelCost,
                jobTotalCost: jobPrice + jobTravelCost,
                hourlyRate: job.hourlyRate,
                employerData: {
                  ...job.employerData,
                  hours: parseFloat(
                    Number(job?.employerData?.hours).toFixed(2),
                  ),
                  jobPrice: employerDataJobPrice,
                  jobTravelCost: employerDataJobTravelCost
                    ? employerDataJobTravelCost
                    : jobTravelCost,
                  jobTotalCost:
                    employerDataJobPrice +
                    (employerDataJobTravelCost
                      ? employerDataJobTravelCost
                      : jobTravelCost || 0),
                },
              };
            });
            setFormSubmitting(true);
            setLoading(true);
            dispatch(
              updateJob({
                id: showJobModal.jobId,
                payload: {
                  jobPositions: [],
                  newPositions: newValues,
                },
                callback: () => {
                  setFormSubmitting(false);
                  setLoading(false);
                  setShowJobModal({ ...showJobModal, visible: false });
                },
                failCallback: () => {
                  setFormSubmitting(false);
                },
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="form">
              <JobPositionForm
                values={values}
                errors={errors}
                touched={touched}
                formSubmitting={formSubmitting}
                setFormSubmitting={setFormSubmitting}
                setFieldValue={setFieldValue}
                places={places}
                jobName={showJobModal.jobName}
                jobBonus={showJobModal.jobBonus}
                setFieldTouched={setFieldTouched}
              />
              <div className="form-bottom">
                <Button
                  type="button"
                  label={t('requestOverview.modal.cancel')}
                  className="btn btn--outline"
                  onClick={handleJobPositionCancel}
                />
                <Button
                  type="submit"
                  label={t('requestOverview.modal.add')}
                  className="btn"
                  disabled={formSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default JobPosition;
