import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import Loader from '../components/Core/Loader';
import Head from '../components/Head';
import InfoModal from '../components/Modal/InfoModal';
import { useSelector } from 'react-redux';
import StaffAppliedJobs from '../components/Staff/StaffAppliedJobs';
import api from '../utils/api';
import { handleError } from '../utils/handleError';
import { getAge, getLanguageName, getShoeSize, getSize } from '../utils/helper';
import StaffMedia from '../components/Staff/StaffMedia';
import GlobalContext from '../context/GlobalContext';

const StaffDetails = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [binaryImages, setBinaryImages] = useState([]);
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { setListLoadingExtern } = useContext(GlobalContext);

  const fetchStaffDetails = async () => {
    try {
      let response;
      if (user?.data?.role === 'superuser') {
        response = await api.get(`/employees/get-staff/${id}`);
      } else {
        const companyId = user?.data?.employer?._id;
        response = await api.get(
          `/employees/get-staff/${id}?companyId=${companyId}`,
        );
        setListLoadingExtern(false);
      }
      setLoading(false);
      setStaff(response.data.data.employee);
    } catch (e) {
      setLoading(false);
      handleError(e);
    }
  };
  useEffect(() => {
    fetchStaffDetails();
  }, []);

  React.useEffect(() => {
    async function getnewimagees() {
      const imagedata = await Promise.all(
        staff?.images.map(async (file, id) => {
          if (typeof file === 'string') {
            const image = file;
            const response = await api.post('users/getImageUrl', {
              image,
            });
            const newimage = response.data.image;

            return newimage;
          } else {
            return file;
          }
        }),
      );
      setBinaryImages(imagedata);
    }

    if (staff && staff?.images && staff?.images.length) {
      getnewimagees();
    }
  }, [staff]);

  const getMultipleLanguagesName = (languages) => {
    const langs = languages.map((lang) => {
      const temp = getLanguageName(lang);
      return temp;
    });
    return langs.toString();
  };

  return (
    <>
      {showInfoModal && (
        <InfoModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
        />
      )}
      {loading && <Loader />}
      <section className="content-section employee-details-section">
        <Head title={t('title.staffDetails')} />
        <Container>
          <div className="box-wrapper--inner">
            <div className="form-back-wrapper mb-5 mt-5 mt-lg-0"></div>
            {!staff && loading === false ? (
              <div>
                <p>No details found!</p>
              </div>
            ) : (
              <>
                {' '}
                <StaffMedia binaryImages={binaryImages} readonly={true} />
                <div className="employee-registration__content employee-details-show__content mt-4 mt-lg-0">
                  <h3>
                    {t('profileof') +
                      ' ' +
                      `${staff?.name ? staff?.name.split(' ')[0] : '-'}`}
                  </h3>
                  <Row>
                    <Col xs="6" md="4" xl="3">
                      <div className="profile-box">
                        <p className="small-text">{t('fnameStaff')}</p>
                        <p className="lead">
                          {staff?.name ? staff?.name.split(' ')[0] : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('gender')}</p>
                        <p className="lead">
                          {staff?.gender ? staff?.gender : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('age')}</p>
                        <p className="lead">
                          {staff?.dateOfBirth
                            ? getAge(
                                moment(staff?.dateOfBirth).tz(process.env.REACT_APP_TIMEZONE).utc().format('YYYY/MM/DD'),
                              )
                            : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('nationality')}</p>
                        <p className="lead">
                          {staff?.nationality ? staff?.nationality : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('hair')}</p>
                        <p className="lead">
                          {staff?.hairColor ? staff?.hairColor : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('height')}</p>
                        <p className="lead">
                          {staff?.height ? staff?.height : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('weight')}</p>
                        <p className="lead">
                          {staff?.weight ? staff?.weight : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xs="6" md="4" xl="3">
                      <div className="profile-box">
                        <p className="small-text">{t('motherTongue')}</p>
                        <p className="lead">
                          {staff?.motherTongue
                            ? getLanguageName(staff?.motherTongue)
                            : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('foreignLanguages')}</p>
                        <p className="lead">
                          {getMultipleLanguagesName(
                            staff?.foreignLanguages ?? [],
                          )}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('size')}</p>
                        <p className="lead">
                          {staff?.size ? getSize(staff?.size) : '-'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('shoeSize')}</p>
                        <p className="lead">
                          {staff?.shoeSize ? getShoeSize(staff?.shoeSize) : ''}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('piercings')}</p>
                        <p className="lead">
                          {staff?.piercings ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('visibleTattoos')}</p>
                        <p className="lead">
                          {staff?.visibleTattoos ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('cgkApproved')}</p>
                        <p className="lead">
                          {staff?.isApproved ? 'YES' : 'NO'}
                        </p>
                      </div>
                    </Col>
                    <Col md="4" xl="3">
                      <div className="profile-box">
                        <p className="small-text">{t('driversLicense')}</p>
                        <p className="lead">
                          {staff?.driversLicense ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('availableCar')}</p>
                        <p className="lead">
                          {staff?.carAvailable ? 'YES' : 'NO'}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('preferedJobs')}</p>
                        <div className="profile-box__tags mt-2">
                          {staff?.preferedJobs && staff?.preferedJobs.length
                            ? staff?.preferedJobs.map((job, jid) => (
                                <label
                                  className="custom-chk tags-item"
                                  key={job + jid}>
                                  <p>{job}</p>
                                </label>
                              ))
                            : ''}
                        </div>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('previousJobs')}</p>
                        <div className="profile-box__tags mt-2">
                          {staff?.previousJobs && staff?.previousJobs.length
                            ? staff?.previousJobs.map((job, jid) => (
                                <label
                                  className="custom-chk tags-item"
                                  key={job + jid}>
                                  <p>{job}</p>
                                </label>
                              ))
                            : ''}
                        </div>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('jobExperience')}</p>
                        <p className="lead">
                          {staff?.jobExperience ? staff?.jobExperience : ''}
                        </p>
                      </div>
                      <div className="profile-box">
                        <p className="small-text">{t('employerRating')}</p>
                        <div className="profile-box__rating">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="#F4CE4E"
                              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="#F4CE4E"
                              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="#F4CE4E"
                              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="#F4CE4E"
                              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="#E5E7EB"
                              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <StaffAppliedJobs
                    staff={staff}
                    fetchStaffDetails={fetchStaffDetails}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default StaffDetails;
