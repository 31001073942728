import { jobConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';
import i18n from '../i18n';
import {
  updateEventByEventID,
  fetchEvents,
  fetchEventsSpecific,
} from './eventAction';

export const getAllJobs = () => {
  const request = () => {
    return { type: jobConstants.GET_ALL_JOBS_REQUEST };
  };
  const success = ({ jobs }) => {
    return {
      type: jobConstants.GET_ALL_JOBS_SUCCESS,
      jobs,
    };
  };
  const failure = (error) => {
    return { type: jobConstants.GET_ALL_JOBS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const jobs = await api.get('employees/getJobs');
      dispatch(
        success({
          jobs: jobs.data.jobs,
        }),
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (
          error.response.data.message ===
          'Your application is still under review. you will get informed via email once it is approved'
        ) {
          toast.error(i18n.t('applicationUnderReview'));
        } else {
          toast.error(`${error.response.data.message}`);
        }
        dispatch(failure(`${error.response.data.message}`));
      } else {
        toast.error(i18n.t('somethingwentwrong'));
        dispatch(failure(i18n.t('somethingwentwrong')));
      }
    }
  };
};

export const getTeaserJobs = () => {
  const request = () => {
    return { type: jobConstants.GET_TEASER_JOBS_REQUEST };
  };
  const success = ({ jobs }) => {
    return {
      type: jobConstants.GET_TEASER_JOBS_SUCCESS,
      jobs,
    };
  };
  const failure = (error) => {
    return { type: jobConstants.GET_TEASER_JOBS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const jobs = await api.get('/job/getTeaserPositions');
      dispatch(
        success({
          jobs: jobs.data.data.jobs,
        }),
      );
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      dispatch(failure(`${error.response.data.message}`));
    }
  };
};

export const getMyJobs = ({ currPage, callback }) => {
  const request = () => {
    return { type: jobConstants.GET_MY_JOBS_REQUEST };
  };
  const success = ({ jobs, currPage, pageCount }) => {
    return {
      type: jobConstants.GET_MY_JOBS_SUCCESS,
      jobs,
      currPage,
      pageCount,
    };
  };
  const failure = (error) => {
    return { type: jobConstants.GET_MY_JOBS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const jobs = await api.get(`employees/getMyJobs?page=${currPage}`);
      if (currPage === 1) {
        dispatch(
          success({
            jobs: [...jobs.data.data.jobs],
            currPage: currPage,
            pageCount: jobs.data.data.pageCount,
          }),
        );
      } else {
        dispatch(
          success({
            jobs: [...getState().jobs.myJobs.jobs, ...jobs.data.data.jobs],
            currPage: currPage,
            pageCount: jobs.data.data.pageCount,
          }),
        );
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (
          error.response.data.message ===
          'Your application is still under review. you will get informed via email once it is approved'
        ) {
          toast.error(i18n.t('applicationUnderReview'));
        } else {
          toast.error(`${error.response.data.message}`);
        }
        dispatch(failure(`${error.response.data.message}`));
        // toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const createJob = ({ payload, callback, failCallback }) => {
  const request = () => {
    return { type: jobConstants.UPDATE_JOB_REQUEST };
  };
  const success = ({ jobs }) => {
    return { type: jobConstants.UPDATE_JOB_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: jobConstants.UPDATE_JOB_FAILURE, error };
  };

  return async (dispatch, getState) => {
    const events = getState().events;
    const eventsList = events.allEvents.events;
    dispatch(request());

    try {
      const res = await api.post(`jobs`, {
        ...payload,
      });
      const createdJob = res.data.data.job;
      if (callback) {
        callback();
      }
      const targetEvent = eventsList.filter((event) => {
        return event._id === createdJob.eventID;
      });

      let newJobs = [...targetEvent[0].jobs, createdJob];
      // if (targetEvent[0].jobs && targetEvent[0].jobs.length) {
      //   newJobs = targetEvent[0].jobs.map((job) => {
      //     if (job._id === id) {
      //       return { ...createdJob };
      //     } else {
      //       return { ...job };
      //     }
      //   });
      // }
      dispatch(
        updateEventByEventID({
          eventID: createdJob.eventID,
          payload: { jobs: newJobs },
        }),
      );
      // dispatch(
      //   success({
      //     events: events.data.data.events,
      //   }),
      // );
    } catch (error) {
      failCallback();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updateJob = ({ id, payload, callback, failCallback }) => {
  const request = () => {
    return { type: jobConstants.UPDATE_JOB_REQUEST };
  };
  const success = ({ jobs }) => {
    return { type: jobConstants.UPDATE_JOB_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: jobConstants.UPDATE_JOB_FAILURE, error };
  };

  return async (dispatch, getState) => {
    // const eventsList = events.allEvents.events;
    dispatch(request());

    try {
      const res = await api.patch(`jobs/updateJob/${id}`, {
        ...payload,
      });
      const updatedJob = res.data.data.job;
      callback();
      // if (
      //   getState().events.currentEvents.events &&
      //   getState().events.currentEvents.events.length
      // ) {
      //   const targetEvent = getState().events.currentEvents.events.filter(
      //     (event) => {
      //       return event.eventID === updatedJob.eventID;
      //     },
      //   );
      //   let newJobs;
      //   if (targetEvent[0].jobs && targetEvent[0].jobs.length) {
      //     newJobs = targetEvent[0].jobs.map((job) => {
      //       if (job._id === id) {
      //         return { ...updatedJob };
      //       } else {
      //         return { ...job };
      //       }
      //     });
      //   }
      //   dispatch(
      //     updateEventByEventID({
      //       eventID: updatedJob.eventID,
      //       payload: { jobs: newJobs },
      //     }),
      //   );
      // }
      if (
        getState().events.allEvents.events &&
        getState().events.allEvents.events.length
      ) {
        const targetEvent = getState().events.allEvents.events.filter(
          (event) => {
            return event._id === updatedJob.eventID;
          },
        );
        let newJobs;
        if (targetEvent[0].jobs && targetEvent[0].jobs.length) {
          newJobs = targetEvent[0].jobs.map((job) => {
            if (job._id === id) {
              return { ...updatedJob };
            } else {
              return { ...job };
            }
          });
        }
        dispatch(
          updateEventByEventID({
            eventID: updatedJob.eventID,
            payload: { jobs: newJobs },
          }),
        );
      }
      // dispatch(
      //   success({
      //     events: events.data.data.events,
      //   }),
      // );
    } catch (error) {
      failCallback();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updateJobPosition = ({ id, payload, callback, failCallback, staff = undefined }) => {
  const request = () => {
    return { type: jobConstants.UPDATE_JOB_POSITION_REQUEST };
  };
  const success = ({ jobs }) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const res = await api.patch(`jobs/updateJobPosition/${id}`, payload);
      const updatedJob = res.data.data.job;
      if (res && res.data) {
        if (
          getState().events.allEvents.events &&
          getState().events.allEvents.events.length
        ) {
          const targetEvent = getState().events.allEvents.events.filter(
            (event) => {
              return event._id === updatedJob.eventID;
            },
          );
          let newJobs;
          if (targetEvent[0].jobs && targetEvent[0].jobs.length) {
            newJobs = targetEvent[0].jobs.map((job) => {
              if (job._id === updatedJob._id) {
                return { ...updatedJob };
              } else {
                return { ...job };
              }
            });
          }
          dispatch(
            updateEventByEventID({
              eventID: updatedJob.eventID,
              payload: { jobs: newJobs },
            }),
          );
          if (staff) {
            await dispatch(fetchEventsSpecific({ staff: staff }, true));
          } else {
            await dispatch(fetchEvents({ currPage: 1 }, true, false));
          }
          callback();
        } else {
          if (staff) {
            await dispatch(fetchEventsSpecific({ staff: staff }, true));
          } else {
            await dispatch(fetchEvents({ currPage: 1 }, true, false));
          }
          callback();
        }
      } else {
        callback();
      }
      // dispatch(
      //   success({
      //     events: events.data.data.events,
      //   }),
      // );
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const handleOverlappingJobs = ({
  id,
  employeeId,
  callback,
  failCallback,
}) => {
  const request = () => {
    return { type: jobConstants.HANDLE_OVERLAPPING_JOBS_REQUEST };
  };
  const failure = (error) => {
    return { type: jobConstants.HANDLE_OVERLAPPING_JOBS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const res = await api.patch(
        `jobs/handleOverlappingJobs/${id}/${employeeId}`,
      );
      if (res && res.data) {
        await dispatch(fetchEvents({ currPage: 1 }, true, false));
        callback();
      } else {
        callback();
      }
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const submitContract = ({ id, payload, callback, failCallback }) => {
  const request = () => {
    return { type: jobConstants.UPDATE_JOB_POSITION_REQUEST };
  };
  const success = ({ jobs }) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const res = await api.patch(`jobs/submitContract/${id}`, payload);
      callback && callback();
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const submitWorkHours = ({ id, payload, callback, failCallback }) => {
  const request = () => {
    return { type: jobConstants.UPDATE_JOB_POSITION_REQUEST };
  };
  const success = ({ jobs }) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_SUCCESS, jobs };
  };
  const failure = (error) => {
    return { type: jobConstants.UPDATE_JOB_POSITION_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const res = await api.patch(`jobs/submitWorkHours/${id}`, payload);
      callback && callback();
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const applyForJob = ({ jobId, positionIds, empRemarks, callback }) => {
  const success = ({ allJobs, myJobs }) => {
    return { type: jobConstants.APPLY_FOR_JOBS_SUCCESS, allJobs, myJobs };
  };
  return async (dispatch, getState) => {
    try {
      const res = await api.post(`jobs/applyJobPosition/${jobId}`, {
        jobPositions: positionIds,
        empRemarks: empRemarks,
      });
      let newAllJobs = [];
      if (res) {
        let tempJob = res.data.data.job;
        const filteredJobPositions = tempJob.jobPositions.filter(
          (position) => !positionIds.toString().includes(position._id),
        );
        const secondFilJobPositions = filteredJobPositions.filter(
          (position) => {
            const flag = position.applications.some(
              (applicant) =>
                applicant.applicant === getState().user.data.employee._id ||
                applicant.applicant._id === getState().user.data.employee._id,
            );
            return !flag;
          },
        );

        tempJob['jobPositions'] = secondFilJobPositions;

        newAllJobs = getState().jobs.allJobs.map((job) => {
          if (job._id === tempJob._id) {
            return tempJob;
          } else {
            return job;
          }
        });
      }

      const currPage = getState().jobs.myJobs.currPage;
      const newMyJobs = await api.get(`employees/getMyJobs?page=${currPage}`);

      dispatch(
        success({
          allJobs: newAllJobs,
          myJobs: {
            jobs: [...newMyJobs.data.data.jobs],
            currPage,
            pageCount: getState().jobs.myJobs.pageCount,
          },
        }),
      );

      callback({
        success: true,
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        callback({
          success: false,
          message: error.response.data.message,
          status: error.response.status,
        });
        // dispatch(failure(`${error.response.data.message}`));
      } else {
        callback({
          success: false,
          message: i18n.t('somethingwentwrong'),
        });
        // dispatch(failure('Something went wrong'));
      }
    }
  };
};

export const bookStaff = ({
  jobId,
  employee,
  remarks,
  callback,
  setSubmitting,
}) => {
  return async (dispatch, getState) => {
    try {
      const res = await api.post(`jobs/bookJobPosition/${jobId}`, {
        employee: employee._id,
        remarks: remarks,
      });

      if (res) {
        let resJob = res.data.data.job;
        let updatedJob = resJob;
        if (resJob && resJob.length) {
          const updatedJobPositions = resJob[0].jobPositions.map((position) => {
            if (position._id === jobId) {
              return {
                ...position,
                booked: {
                  ...position.booked,
                  employee,
                  remarks,
                },
              };
            } else {
              return position;
            }
          });
          updatedJob[0]['jobPositions'] = updatedJobPositions;
        }
        if (
          getState().events.allEvents.events &&
          getState().events.allEvents.events.length &&
          updatedJob &&
          updatedJob.length
        ) {
          const targetEvent = getState().events.allEvents.events.filter(
            (event) => {
              return event._id === updatedJob[0].eventID;
            },
          );
          let newJobs;
          if (targetEvent[0].jobs && targetEvent[0].jobs.length) {
            newJobs = targetEvent[0].jobs.map((job) => {
              if (job._id === updatedJob[0]._id) {
                return { ...updatedJob[0] };
              } else {
                return { ...job };
              }
            });
          }
          dispatch(
            updateEventByEventID({
              eventID: updatedJob[0].eventID,
              payload: { jobs: newJobs },
            }),
          );
        }
      }
      callback();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (
          error.response.data.message ===
          'Staff is blocked on this date and time. Please check after 45 mins'
        ) {
          toast.error(i18n.t('staffBloked'));
        } else if (
          error.response.data.message ===
          'Staff is already booked on this date!'
        ) {
          toast.error(i18n.t('staffBooked'));
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error('Failed to book staff!');
      }
    }
  };
};

export const checkEmployeestatus = ({
  jobId,
  employee,
  callback,
  failCallback,
}) => {
  return async (dispatch, getState) => {
    try {
      const res = await api.post(`jobs/checkEmployeestatus/${jobId}`, {
        employee: employee._id,
      });
      if (res) {
        if (res.data.status === 'success') {
          callback();
        }
      }
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (
          error.response.data.message ===
          'Staff is blocked on this date and time. Please check after 45 mins'
        ) {
          toast.error(employee.name + ' ' + i18n.t('staffBloked'));
        } else if (
          error.response.data.message ===
          'Staff is already booked on this date!'
        ) {
          toast.error(employee.name + ' ' + i18n.t('staffBooked'));
        } else {
          toast.error(employee.name + ' ' + error.response.data.message);
        }
      } else {
        toast.error('Failed to book staff!');
      }
    }
  };
};

export const checkRejectedEmployeestatus = ({
  jobId,
  employee,
  callback,
  failCallback,
}) => {
  return async (dispatch, getState) => {
    try {
      const res = await api.post(`jobs/checkRejectedEmployeestatus/${jobId}`, {
        employee: employee._id,
      });
      if (res) {
        if (res.data.status === 'success') {
          callback();
        } else if (res.data.status === 'fail') {
          failCallback();
          toast.error('Staff is rejected for this position!');
        }
      }
    } catch (error) {
      if (failCallback) {
        failCallback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(employee.name + ' ' + error.response.data.message);
      } else {
        toast.error('Failed to book staff!');
      }
    }
  };
};

const singleApicall = async (job) => {
  return new Promise((resolve, reject) => {
    api
      .post(`jobs/payoutstandingamount`, {
        jobpositions: [
          {
            _id: job?._id,
            amount:
              job?.employerData?.outstandingAmount > 0 &&
              !job?.employerData?.paidOutstandingAmount
                ? job?.employerData?.outstandingAmount
                : 0,
          },
        ],
      })
      .then((res) => {
        setTimeout(() => {
          resolve(res);
        }, 2000);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const payOutstandingAmount = ({ jobs, callback, setSubmitting }) => {
  return async (dispatch, getState) => {
    try {
      // function testPromise(time) {
      //   return new Promise((resolve, reject) => {
      //     setTimeout(() => {
      //       // console.log(`Processing ${time}`);
      //       resolve(time);
      //     }, time);
      //   });
      // }

      let result = jobs.reduce((accumulatorPromise, job) => {
        return accumulatorPromise.then(() => {
          return singleApicall(job);
        });
      }, Promise.resolve());

      result.then((e) => {
        callback();
        setSubmitting(false);
        // console.log("All Promises Resolved !!✨")
      });
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to Update one or more Job Position');
      }
    }
  };
};
