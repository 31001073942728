import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import shortid from 'shortid';
import { fetchTestimonials } from '../../actions/testimonialAction';

const SimpleSlider = () => {
  const dispatch = useDispatch();
  const testimonialsData = useSelector((state) => state.testimonials);
  const [testimonials, setTestimonials] = useState([]);

  const settings = {
    dots: true,
    nav: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    async function getdata() {
      dispatch(fetchTestimonials());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    if (
      testimonialsData &&
      testimonialsData?.testimonialsList &&
      testimonialsData?.testimonialsList.length === 0
    ) {
      getdata();
    }
  }, []);
  useEffect(() => {
    if (
      testimonialsData &&
      testimonialsData.testimonialsList &&
      testimonialsData.testimonialsList.length
    ) {
      setTestimonials(testimonialsData.testimonialsList);
    }
  }, [testimonialsData]);

  useEffect(() => {
    if (testimonials && testimonials.length) {
      updatetestimonials();
    }
    async function updatetestimonials() {
      const newtesti = await Promise.all(
        testimonials.map(async (img, index) => {
          if (
            img &&
            img.profile &&
            typeof img.profile === 'string' &&
            img.profile.startsWith('public/upload/testimonials/')
          ) {
            img.profile = `${process.env.REACT_APP_GCS_BASE_URL}/${img.profile}`;
          }
          return img;
        }),
      );
      setTestimonials([...newtesti]);
    }
  }, [testimonials.length]);

  const renderTestimonials = ({ testimonials }) => {
    if (testimonials && testimonials.length) {
      return testimonials.map((testimonial) => (
        <div className="testimonial-slide" key={shortid.generate()}>
          <div className="testimonial-block">
            <div className="testimonial-block__content">
              <h3>{testimonial.quote}</h3>
              <p className="testimonial-block__name">
                <span>{testimonial.name},</span>
                <span>{testimonial.position}</span>
              </p>
            </div>
            <div className="testimonial-block__image">
              {testimonial?.profile ? (
                <img src={testimonial.profile} alt="Testimonial" />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <section className="testimonial-slider-section">
      {testimonialsData.loading ? (
        <div className="testimonial-slide">
          <div className="testimonial-block">
            <div className="testimonial-block__content"></div>
            <div className="testimonial-block__image"></div>
          </div>
        </div>
      ) : (
        <Slider {...settings} className="testimonial-slider" arrows={false}>
          {renderTestimonials({ testimonials })}
        </Slider>
      )}
    </section>
  );
};

export default SimpleSlider;
