import moment from 'moment-timezone';

export const manageStartEndTime = (userDate, jobStartDate) => {
  let time =
    typeof userDate === 'number' ? userDate : new Date(userDate).valueOf();
  time = moment(time).set('second', 0).set('millisecond', 0);
  const currTime = moment(Date.now())
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const timeDiff = time - currTime;
  const jobDateTime = moment(jobStartDate)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const changedTime = jobDateTime + timeDiff;
  return changedTime;
};


function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}
export const convertMsToHM = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};
