import React, { useEffect, useRef, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Croppie from 'croppie';
import Button from '../Core/Button';
import { dataUrlToFile } from '../../utils/dataUrlToFile';
import zoomInIcon from '../../assets/images/icons/icon-plus.svg';
import zoomOutIcon from '../../assets/images/icons/icon-minus.svg';
import 'croppie/croppie.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IMAGETYPE } from '../../utils/constants';

let vanilla;
const EmployerImageChange = ({
  showEmployerImageModal,
  setShowEmployerImageModal,
  uploadStep,
  setUploadStep,
  companyPhoto,
  setCompanyPhoto,
}) => {
  const img = useRef(null);
  const [zoom, setZoom] = useState(0);
  const [photo, setPhoto] = useState(null);
  const [filteredPhoto, setFilteredPhoto] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const timer = () =>
      setInterval(() => {
        if (vanilla) {
          vanilla.setZoom(zoom);
          vanilla.result('base64').then((base64) => {
            setFilteredPhoto(base64);
          });
        }
      }, 2000);
    const timerId = timer();
    return () => {
      clearInterval(timerId);
    };
  }, [zoom]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    const el = document.getElementById('logoImg');
    if (el && photo) {
      URL.revokeObjectURL(photo.preview);
      vanilla = new Croppie(el, {
        viewport: { width: 200, height: 200, type: 'circle' },
        boundary: { width: 300, height: 300 },
        showZoomer: true,
        enableOrientation: true,
      });

      vanilla.bind({
        url: photo.preview,
        // orientation: 4,
        // points: [77, 469, 280, 739],
      });
    }
  }, [photo]);

  useEffect(() => {
    if (photo) {
      setFilteredPhoto(URL.createObjectURL(photo));
    }
  }, [photo]);

  const changeImageSize = (size) => {
    setZoom(size);
    vanilla.setZoom(size);
    vanilla.result('base64').then((base64) => {
      setFilteredPhoto(base64);
    });
  };

  return (
    <>
      <Modal
        show={showEmployerImageModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => (
          setShowEmployerImageModal(false), setPhoto(null), setUploadStep(1)
        )}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{t('imageModel.uploadImage')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form form-image-upload form-profile-img-upload">
            <Row>
              <Col md="12">
                {uploadStep === 1 && (
                  <div className="file-upload-btn-wrapper">
                    <div className="file-upload-content">
                      <div className="file-upload-icon"></div>
                      <div className="file-upload-text">
                        <p className="text-uppercase text-bold mb-4">
                          {t('DragDropimage')}
                          <br /> {t('hereor')}{' '}
                          <span>{t('requestOverview.modal.browse')}</span>
                        </p>
                        <p>{t('imageModel.uploadNote')}</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];

                        const issupprtedimage = !IMAGETYPE.includes(file.type);

                        // if (issupprtedimage) {
                        //   toast.error(t('uploadValidImage'));
                        //   return;
                        // }
                        if (file.size > 2097152) {
                          toast.error(t('imageModel.uploadSizeError'));
                          return;
                        } else {
                          setPhoto(
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            }),
                          );
                          setUploadStep(2);
                        }
                      }}
                      className={`form-control`}
                    />
                  </div>
                )}
                {uploadStep === 2 && (
                  <Row>
                    <Col sm="12" md="6" className="position-relative">
                      <h4>your image</h4>
                      <img
                        id="logoImg"
                        src={URL?.createObjectURL(photo)}
                        alt="Logo"
                      />
                      <span
                        className="d-inline-block img-zoom-out"
                        onClick={() => {
                          const zoomIn = zoom - 0.1;
                          changeImageSize(zoomIn);
                        }}>
                        <img src={zoomOutIcon} alt="Zoom" />
                      </span>
                      <span
                        className="d-inline-block img-zoom-in"
                        onClick={() => {
                          const zoomIn = zoom + 0.1;
                          changeImageSize(zoomIn);
                        }}>
                        <img src={zoomInIcon} alt="Zoom" />
                      </span>
                    </Col>
                    <Col sm="12" md="1"></Col>
                    <Col sm="12" md="5">
                      <h4>preview</h4>
                      <div className="cropped-img-in">
                        <img
                          ref={img}
                          src={filteredPhoto}
                          alt="Logo"
                          style={{
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-bottom justify-content-end">
                        <Button
                          type="submit"
                          label={t('orgImageModal.back')}
                          className="btn btn--outline"
                          onClick={(e) => setUploadStep(1)}
                        />
                        <Button
                          type="button"
                          label={t('save')}
                          className="btn primary-btn"
                          onClick={async (e) => {
                            const resultImage = await vanilla.result('base64');
                            const filePhoto = await dataUrlToFile(
                              resultImage,
                              'profile.jpg',
                            );
                            setCompanyPhoto(filePhoto);
                            setUploadStep(1);
                            setShowEmployerImageModal(false);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployerImageChange;
