import { languages } from './languages';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { shoeSizeOptions, sizeOptions } from './constants';
import moment from 'moment';
import apiv2 from './apiv2';
import jwtDecode from 'jwt-decode';
import { logout } from '../actions/authAction';
import { toast } from 'react-toastify';

export const checkBookedStaff = (positions) => {
  let bookedDetails = {
    staffNo: 0,
    amount: 0,
    outstandingAmount: 0
  };
  // eslint-disable-next-line array-callback-return
  positions.map((position) => {
    if (position.booked && position.booked.employee) {
      let amount = bookedDetails.amount + position.jobTotalCost;
      if (position.employerData.outstandingAmount){
        bookedDetails.outstandingAmount += position.employerData.outstandingAmount;
      }
      if (position.booked && position?.employerData?.jobTotalCost) {
        amount = bookedDetails.amount + position?.employerData?.jobTotalCost;
      }
      bookedDetails = {
        staffNo: bookedDetails.staffNo + 1,
        amount: amount,
        outstandingAmount: bookedDetails.outstandingAmount
      };
    }
  });
  return bookedDetails;
};

export const convertToIntHour = (time) => {
  if (!time) {
    return 0;
  }
  const t = time
    .replace(':15', '.25')
    .replace(':30', '.5')
    .replace(':45', '.75');
  return parseFloat(t);
};

export const getLanguageName = (code) => {
  const filtered = languages.filter((l) => l.code === code);

  return filtered && filtered.length && filtered[0] ? filtered[0].name : '-';
};

export const getSize = (size) => {
  let sizeOps = [];
  sizeOptions.map((s) => {
    sizeOps = [...sizeOps, ...s.options];
  });
  const filtered = sizeOps.filter((s) => {
    return s.value === size;
  });
  return filtered && filtered.length && filtered[0] ? filtered[0].label : '-';
};

export const getShoeSize = (size) => {
  const filtered = shoeSizeOptions.filter((s) => s.value === size);
  return filtered && filtered.length && filtered[0] ? filtered[0].label : '-';
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getUnsignedURL = (url) => {
  const bucket = `${process.env.REACT_APP_GCS_BUCKET}/`;
  const end = '?X-';
  // const finalURL = url.match(/dsr-superstaff-assets-dev\/(.*?)??X-/);
  const finalURL = url.match(new RegExp(bucket + '(.*?)' + end));
  if (finalURL && finalURL.length === 2) {
    return finalURL[1].replace('?', '');
  } else {
    return '';
  }
};

export const showExactTime = (time) => {
  let newTime = time;
  if (newTime.getMinutes() > 0 && newTime.getMinutes() < 15) {
    newTime.setMinutes('00');
  } else if (newTime.getMinutes() > 15 && newTime.getMinutes() < 30) {
    newTime.setMinutes('15');
  } else if (newTime.getMinutes() > 30 && newTime.getMinutes() < 45) {
    newTime.setMinutes('30');
  } else if (newTime.getMinutes() > 45 && newTime.getMinutes() < 60) {
    newTime.setMinutes('45');
  }
  return newTime;
};

export const getPriceByDurationHours = (end, start, pph) => {
  let dateDiff = moment.duration(moment(end).diff(moment(start)));
  let dateCeil = Math.ceil(dateDiff.asMinutes());
  dateCeil = dateCeil / 60;
  // const a1 = moment(end);
  // const b1 = moment(start);
  // let duration = a1.diff(b1, 'hours');
  let duration = dateCeil <= 4 ? 4 : dateCeil;
  return duration * pph;
};

export const getTimeDifferent = (start, end) => {
  let startTime = new Date(start);
  let endTime = new Date(end);
  startTime.setSeconds(0, 0);
  endTime.setSeconds(0, 0);
  if (endTime.getTime() < startTime.getTime()) {
    endTime.setDate(startTime.getDate() + 1);
    endTime.setSeconds(0);
  }
  startTime = startTime.getTime();
  endTime = endTime.getTime();
  const diffMS = endTime - startTime;
  const diffS = diffMS / 1000;
  const diffM = diffS / 60;
  const diffH = diffM / 60;
  return diffH;
};

export const getAddressFromLatLng = (lat, lng, onSuccess, onError) => {
  apiv2
    .post('/geocoding/geocode-lat-lng', { lat, lng })
    .then((response) => {
      if (response.status !== 200 && response.status !== 201) {
        onError(new Error(response.status + ''));
        return;
      }
      response = response.data.data;
      const address = response.results[0].formatted_address;
      let city, state, country, postal_code;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              city = response.results[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              state = response.results[0].address_components[i].long_name;
              break;
            case 'country':
              country = response.results[0].address_components[i].long_name;
              break;
            case 'postal_code':
              postal_code = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      onSuccess && onSuccess({ address, city, state, country, postal_code });
    })
    .catch(onError);
};

export const printPriceValue = (price) => {
  return isNaN(price) ? 0 : Number(price).toFixed(2);
};

// function for calculate job position data while create job position.
export const calculateJobPossitionData = (
  values,
  currentStaffType,
  jobPosition,
) => {
  let hours = getTimeDifferent(values.start, values.end);
  hours = hours < 4 ? 4 : hours;
  const pricePerHour = currentStaffType?.pricePerHour;
  const jobPrice = hours * pricePerHour;
  const jobAmount = jobPrice ? parseFloat(Number(jobPrice).toFixed(2)) : 0;

  const effectivePricePerHour = currentStaffType?.effectivePricePerHour;
  const effectiveJobPrice = hours * effectivePricePerHour;
  const effectiveJobAmount = effectiveJobPrice
    ? parseFloat(Number(effectiveJobPrice).toFixed(2))
    : 0;

  return {
    employerData: {
      ...jobPosition?.employerData,
      jobPrice: effectiveJobAmount,
      hourlyRate: effectivePricePerHour,
      jobTravelCost: jobPosition?.jobTravelCost,
      jobTotalCost: effectiveJobAmount + jobPosition?.jobTravelCost,
    },
    jobPrice: jobAmount,
    hourlyRate: pricePerHour,
    jobTravelCost: jobPosition?.jobTotalCosts,
    jobTotalCost: jobAmount + jobPosition?.jobTravelCost,
  };
};

export const calculateOutstandingAmount = (
  values,
  currentStaffType,
  jobPosition,
  jobBonus,
) => {
  let hours = getTimeDifferent(values.start, values.end);
  hours = hours < 4 ? 4 : hours;
  // let hourlyRate = currentStaffType?.pricePerHour;
  let hourlyRate = currentStaffType?.effectivePricePerHour;
  if (jobPosition?.hourlyRate && jobPosition?.hourlyRate !== 'undefined') {
    hourlyRate = jobPosition?.hourlyRate;
  }
  let effectivePricePerHour = currentStaffType?.effectivePricePerHour;
  if (
    !jobPosition?.employerData?.hourlyRate &&
    jobPosition?.employerData?.hourlyRate !== 'undefined'
  ) {
    effectivePricePerHour = jobPosition?.employerData?.hourlyRate;
  }
  const confirmJobPrice =
    hours * hourlyRate +
    (jobBonus && jobBonus > 0 ? hours * hourlyRate * (jobBonus / 100) : 0);
  const outstandingAmount = confirmJobPrice - jobPosition?.jobPrice;
  const confirmJobTotalCost = confirmJobPrice + jobPosition?.jobTravelCost;
  const confirmEffectiveJobPrice =
    hours * effectivePricePerHour +
    +(jobBonus && jobBonus > 0
      ? hours * effectivePricePerHour * (jobBonus / 100)
      : 0);
  const confirmEffectiveJobTotalCost =
    confirmEffectiveJobPrice +
    (jobPosition?.employerData?.jobTravelCost
      ? jobPosition?.employerData?.jobTravelCost
      : jobPosition?.jobTravelCost || 0);
  const effectiveOutstandingAmount =
    confirmEffectiveJobPrice - jobPosition?.employerData?.jobPrice;

  // console.log("lallu", confirmEffectiveJobPrice, "(", confirmEffectiveJobTotalCost, ")", jobPosition?.employerData?.jobPrice);
  return {
    employerData: {
      ...jobPosition?.employerData,
      confirmJobPrice: confirmEffectiveJobPrice
        ? parseFloat(Number(confirmEffectiveJobPrice).toFixed(2))
        : 0,
      outstandingAmount: effectiveOutstandingAmount
        ? parseFloat(Number(effectiveOutstandingAmount).toFixed(2))
        : 0,
      confirmJobTotalCost: confirmEffectiveJobTotalCost
        ? parseFloat(Number(confirmEffectiveJobTotalCost).toFixed(2))
        : 0,

      paidOutstandingAmount:
        effectiveOutstandingAmount > 0
          ? false
          : jobPosition?.employerData?.paidOutstandingAmount,
    },
    confirmJobPrice: confirmJobPrice
      ? parseFloat(Number(confirmJobPrice).toFixed(2))
      : 0,
    confirmJobTotalCost: confirmJobTotalCost
      ? parseFloat(Number(confirmJobTotalCost).toFixed(2))
      : 0,
    outstandingAmount: outstandingAmount
      ? parseFloat(Number(outstandingAmount).toFixed(2))
      : 0,
    isConfirmed: true,
    paidOutstandingAmount:
      outstandingAmount > 0 ? false : jobPosition?.paidOutstandingAmount,
  };
};

export const getTimeDifferenceHours = (start, end) => {
  let dateS = new Date(start);
  let dateE = new Date(end);
  let date1 = dateS.setMilliseconds(0);
  let date2 = dateE.setMilliseconds(0);
  let diff = new Date(date2).getTime() - new Date(date1).getTime();
  let msec = diff;
  let hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  let mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  let flag = true;
  if (hh >= 10) {
    if (mm !== 0 || hh > 10) {
      flag = false;
    } else {
      flag = true;
    }
  } else if (Math.sign(hh) === -1 && hh >= -14) {
    if (hh === -14 && mm === 0) {
      flag = true;
    }
    if (mm !== 0 || hh > -14) {
      flag = false;
    }
  } else if (hh === 0 && mm === 0) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
};

export const htmlDecode = (input) => {
  let e = document.createElement('div');
  e.innerHTML = input;
  if (e.childNodes.length > 0) {
    return e.childNodes[0].nodeValue;
  } else {
    return e.nodeValue;
  }
};

export const checkUserRoleAndTokenExpiration = (auth) => {
  let role,
    tokenExpired = false,
    invalidToken = false;

  if (auth && JSON.parse(localStorage.getItem('user'))) {
    role = JSON.parse(localStorage.getItem('user')).role;

    const token = localStorage.getItem('token');
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        tokenExpired = true;
      }
    } catch (error) {
      invalidToken = true;
    }
  }

  return { role, tokenExpired, invalidToken };
};

export const useHandleErrorMessage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleErrorMessage = (errorMessage) => {
    const isErrorMessageShown = localStorage.getItem('isErrorMessageShown');
    if (!isErrorMessageShown) {
      localStorage.setItem('isErrorMessageShown', true);
      dispatch(
        logout(() => {
          toast.error(errorMessage);
          localStorage.removeItem('isErrorMessageShown');
          history.push('/');
        }, true),
      );
    }
  };

  return handleErrorMessage;
};

export const sortByDateInMilliseconds = (arr) => {
  arr.sort(function (a, b) {
    const dateA = a.jobStart;
    const dateB = b.jobStart;

    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const dateToWeekDay = (date) => {
  let d = new Date(date);
  return d.getDay();
};

export const dateToTime = (date) => {
  let d = new Date(date);
  let minutes = d.getMinutes();
  let hours = d.getHours();
  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes)
  );
};

export const stringDateSort = (a, b, toString, desc) => {
  const dateAString = a.substring(0, a.indexOf(toString));
  const dateBString = b.substring(0, b.indexOf(toString));
  const dateA = moment(dateAString, 'dddd DD.MM.YYYY').toDate();
  const dateB = moment(dateBString, 'dddd DD.MM.YYYY').toDate();

  if (dateA < dateB) {
    return desc ? 1 : -1;
  } else if (dateA > dateB) {
    return desc ? -1 : 1;
  } else {
    return 0;
  }
};

export const constructGCSImageUrl = (image) => {
  const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
  const isValidHTTPURL = (url) => urlPattern.test(url);
  const baseBucketUrl = `${process.env.REACT_APP_GCS_BASE_URL}/public/upload/users/`;
  if (
    typeof image === 'string' &&
    (image.startsWith('employee-') || image.startsWith('user-'))
  ) {
    return baseBucketUrl + image;
  } else if (typeof image === 'string' && isValidHTTPURL(image)) {
    return image;
  } else {
    return;
  }
};

export const getTimeWithOffset = (time, date, timezone) => {
  const parsedTime = moment(time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  const adjustedTime = moment.tz(date, timezone).set({
    hour: parsedTime.get('hours'),
    minute: parsedTime.get('minutes'),
  });
  return adjustedTime.valueOf();
};


export const endTimeEndsNextDay = (startTime, endTime) => {
  startTime = parseInt(startTime);
  endTime = parseInt(endTime);
  return endTime < startTime ? endTime + 24 * 60 * 60 * 1000 : endTime;
};
