import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
// import profileImage1 from '../../assets/images/table-book-profile.png';
// import { getJobHours } from '../../pages/JobWallDetails';
import { getTimeDifferent } from '../../utils/helper';
import { convertMsToHM } from '../../utils/time';
import { useTranslation } from 'react-i18next';
import defaultAvatar from '../../assets/images/default-avatar.png';

import api from '../../utils/api';

function PositionRow({
  position,
  job,
  showConfirmHoursModal,
  setShowConfirmHoursModal,
}) {
  const { t } = useTranslation();
  const [image, setImage] = React.useState();
  React.useEffect(() => {
    if (position?.booked?.employee?.images?.length) {
      if (
        typeof position.booked.employee.images[0] === 'string' &&
        !position.booked.employee.images[0].includes(
          'https://storage.googleapis.com',
        ) &&
        (position.booked.employee.images[0].startsWith('employee-') ||
          position.booked.employee.images[0].startsWith('user-'))
      ) {
        getImage(position.booked.employee.images[0]);
      }
    }
    async function getImage(image) {
      const response = await api.post('users/getImageUrl', { image });
      const newimage = response.data.image;
      setImage(newimage);
    }
  }, []);

  const workhours = getTimeDifferent(
    position?.confirmJobStart ? position?.confirmJobStart : position?.jobStart,
    position?.confirmJobEnd ? position?.confirmJobEnd : position?.jobEnd,
  );

  const workhours1 = convertMsToHM(
    (position?.confirmJobEnd ? position?.confirmJobEnd : position?.jobEnd) -
      (position?.confirmJobStart
        ? position?.confirmJobStart
        : position?.jobStart),
  );
  return (
    <tr key={position._id}>
      <td>
        <span>{moment(position.jobDate).tz(process.env.REACT_APP_TIMEZONE).format('dddd DD.MM.YY')}</span>
      </td>
      <td>
        <span>{position.jobPlace}</span>
      </td>
      <td>
        <span>
          {position.confirmJobStart
            ? moment(position.confirmJobStart).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')
            : moment(position.jobStart).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')}
        </span>
      </td>
      <td>
        <span>
          {position.confirmJobEnd
            ? moment(position.confirmJobEnd).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')
            : moment(position.jobEnd).tz(process.env.REACT_APP_TIMEZONE).format('HH:mm')}
        </span>
      </td>
      <td>
        <span className="text-nowrap">
          €{' '}
          {position?.employerData?.confirmJobPrice > 0
            ? Number(position?.employerData?.confirmJobPrice).toFixed(2)
            : Number(
                position?.employerData?.jobPrice
                  ? position?.employerData?.jobPrice
                  : 0,
              ).toFixed(2)}
        </span>
      </td>
      <td>
        <span className="text-nowrap">
          €{' '}
          {Number(
            position?.employerData?.jobTravelCost
              ? position?.employerData?.jobTravelCost
              : 0,
          ).toFixed(2)}
        </span>
      </td>
      <td>
        <span className="text-nowrap">
        {`${workhours1} ${workhours > 6 ? '*' : ''}`}
        </span>
      </td>
      <td>
        <div className="profile-book">
          <Link
            className="profile-wrap"
            to={`/staff-details/${position?.booked?.employee?._id}`}
            target="_blank">
            <span className="profile-image">
              {position?.booked?.employee?.images ? (
                <img
                  src={`${
                    position.booked.employee.images.length &&
                    typeof position.booked.employee.images[0] === 'string' &&
                    !position.booked.employee.images[0].includes(
                      'https://storage.googleapis.com',
                    ) &&
                    (position.booked.employee.images[0].startsWith(
                      'employee-',
                    ) ||
                      position.booked.employee.images[0].startsWith('user-'))
                      ? image
                      : String(defaultAvatar)
                  }`}
                  alt={t('profile')}
                />
              ) : (
                <img src={defaultAvatar} alt={t('profile')} />
              )}
            </span>
            {position?.booked?.employee?.gender === 'male' ? (
              <span className="profile-gender male"></span>
            ) : position?.booked?.employee?.gender === 'female' ? (
              <span className="profile-gender female"></span>
            ) : (
              <span className="profile-gender other"></span>
            )}
          </Link>
          <span
            className="ms-2 link"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() =>
              setShowConfirmHoursModal({
                ...showConfirmHoursModal,
                visible: true,
                data: {
                  position,
                  job,
                },
              })
            }>
            {position.isConfirmed ? t('Confirmed') : t('Confirm_hours')}
          </span>
        </div>
      </td>
      <td>
        <span className="text-nowrap">
          €{' '}
          {Number(
            position?.employerData?.outstandingAmount
              ? position?.employerData?.outstandingAmount
              : 0,
          ).toFixed(2)}
        </span>
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <span className="placeholder-text">{t('event.totalExcl')}</span>
        <strong className="total-amount">
          €{' '}
          {position?.employerData?.confirmJobTotalCost
            ? Number(position?.employerData?.confirmJobTotalCost).toFixed(2)
            : Number(
                position?.employerData?.jobTotalCost
                  ? position?.employerData?.jobTotalCost
                  : 0,
              ).toFixed(2)}
        </strong>
      </td>
    </tr>
  );
}

export default PositionRow;
