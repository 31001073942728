import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useTable, useSortBy } from 'react-table';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Head from '../components/Head';
import Button from '../components/Core/Button';
import { applyForJob } from '../actions/jobAction';
import { addAppliedEvents } from '../actions/authAction';
import apiv2 from '../utils/apiv2';
import { labelRoles } from '../utils/constants';
import { htmlDecode } from '../utils/helper';
import Loader from '../components/Core/Loader';
import Textarea from '../components/Core/Textarea';
import { useFormik } from 'formik';
import { convertMsToHM } from '../utils/time';
import 'moment/locale/de';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );
  return (
    <div className="table-responsive">
      <table
        className="table remove-space job-details-table"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'yourSalary') {
                    return (
                      <td {...cell.getCellProps()}>
                        <span className="total-amount">
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const getJobHours = (endTime, startTime) => {
  if (startTime > endTime) {
    return endTime + 24 - startTime;
  } else {
    return endTime - startTime;
  }
};

const filterEvent = (currentEvent, jobId) => {
  for (let i = 0; i < currentEvent.length; i++) {
    for (let j = 0; j < currentEvent[i].jobPositions.length; j++) {
      if (currentEvent[i].jobPositions[j]._id === jobId) {
        return currentEvent[i];
      }
    }
  }
  return null; // Return null if no matching JobPosition is found
};

const filterJob = (job, jobId) => {
  if (!job) {
    throw new Error('Invalid job object');
  }
  const jobPositions = job.jobPositions || [];
  const amountPositions = jobPositions.length;
  const filteredJobPositions = [];
  const duplicateIdsArray = [];
  const currentJobPosition = job.jobPositions.find(
    (currentPos) => currentPos._id === jobId,
  );
  filteredJobPositions.push(currentJobPosition);

  for (let i = 0; i < amountPositions; i++) {
    const position = jobPositions[i];
    position.duplicateIds = [];

    if (position._id === jobId) {
      continue; // Skip to the next iteration if the conditions are not met
    }
    const nextPosition = jobPositions[i];
    if (
      currentJobPosition.jobPlace === nextPosition.jobPlace &&
      Math.abs(currentJobPosition.jobDate - nextPosition.jobDate) < 600 &&
      Math.abs(currentJobPosition.jobStart - nextPosition.jobStart) < 600 &&
      Math.abs(currentJobPosition.jobEnd - nextPosition.jobEnd) < 600 &&
      !jobPositions[i].isBooked
    ) {
      duplicateIdsArray.push(nextPosition._id);
    }
    filteredJobPositions.push(position);
  }

  const filteredPositions = filteredJobPositions.map((position) => {
    return {
      jobId: job.jobId,
      id: position._id,
      duplicateIds: duplicateIdsArray,
      jobPosition: job.jobName,
      companyName: job.companyName,
      jobDescription: job.jobDescription,
      jobRequirements: job.jobRequirements,
      jobPrice: Number(position.jobPrice).toFixed(2),
      jobTotalCost: Number(position?.jobTotalCost).toFixed(2),
      hours: `${convertMsToHM(position.jobEnd - position.jobStart)}`,
      jobTravelCost: Number(position.jobTravelCost).toFixed(2),
      eventName: job.eventName,
      eventID: job.eventID,
      place: position.jobPlace,
      quantity: position.duplicateIds.length + 1,
      date: moment(position.jobDate).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('dddd DD.MM.yyyy'),
      date1: position.jobDate,
      startTime: moment(position.jobStart).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('HH:mm'),
      endTime: moment(position.jobEnd).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('HH:mm'),
      applyUntil: moment(job.eventExpirationDate).tz(process.env.REACT_APP_TIMEZONE).utc(true).format('DD.MM.yyyy'),
    };
  });
  return filteredPositions;
};

const JobWallDetails = (props) => {
  // let query = useQuery();
  let dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  moment.locale(localStorage.getItem('i18nextLng') ?? 'de');

  const { t, i18n } = useTranslation();

  async function fetchMyAPI() {
    if (
      userData.data.active === true &&
      userData.data.employee.isApproved === true
    ) {
      setLoading(true);
      let filteredJob;
      try {
        const response = await apiv2.get(
          `/job/by-event/${props.match.params.eventID}`,
        );
        const currentEvent = response.data;
        const matchedJobPosition = filterEvent(
          currentEvent,
          props.match.params.id,
        );
        filteredJob = filterJob(matchedJobPosition, props.match.params.id);
      } catch (error) {
        console.error('MyError:', error.message);
      }
      let correctJob = filteredJob && filteredJob.length ? filteredJob[0] : {};
      setJob(correctJob);
      setLoading(false);
    } else {
      toast.error(i18n.t('applicationUnderReview'));
    }
  }

  useEffect(() => {
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
      },
      {
        Header: () => {
          return `${t('placeplaceholder')}`;
        },
        accessor: 'place',
      },
      {
        Header: () => {
          return `${t('startLabel')}`;
        },
        accessor: 'startTime',
      },
      {
        Header: () => {
          return `${t('endLabel')}`;
        },
        accessor: 'endTime',
      },
      {
        Header: () => {
          return `${t('profile.price')}`;
        },
        accessor: 'price',
      },
      {
        Header: () => {
          return `${t('requestOverview.travelCosts') + '*'}`;
        },
        accessor: 'travelCosts',
      },
      {
        Header: () => {
          return `${t('event.hours')}`;
        },
        accessor: 'hours',
      },
      {
        Header: () => {
          return `${t('myJob.salary') + '**'}`;
        },
        accessor: 'yourSalary',
      },
    ];
  }, []);

  const data = React.useMemo(() => {
    return [
      {
        date: job && job.date ? job.date : '0',
        place: job && job.place,
        startTime: job && job.startTime ? job.startTime : '0',
        endTime: job && job.endTime ? job.endTime : '0',
        price: `€ ${
          job && job.jobPrice >= 0 ? Number(job.jobPrice).toFixed(2) : 0
        }`,
        travelCosts: `€ ${
          job && job.jobTravelCost >= 0
            ? Number(job.jobTravelCost).toFixed(2)
            : 0
        }`,

        hours: job && job.hours ? job.hours : '0',
        yourSalary: `€ ${job && Number(job?.jobTotalCost).toFixed(2)}`,
      },
    ];
  });

  const formik = useFormik({
    initialValues: {
      empRemarks: '',
    },
    enableReinitialize: true,
  });

  const handleApply = async () => {
    setIsSubmitting(true);
    setApplyLoading(true);
    dispatch(
      applyForJob({
        jobId: job.jobId,
        positionIds:
          job.duplicateIds && job.duplicateIds.length
            ? [...job.duplicateIds, job.id]
            : [job.id],
        empRemarks: formik.values.empRemarks,
        callback: ({ success, message, events, status }) => {
          setIsSubmitting(false);
          setApplyLoading(false);
          history.push({
            pathname: `/${labelRoles.employee}/job-wall`,
          });

          if (events) {
            dispatch(addAppliedEvents(events));
          }
          if (success) {
            toast.success(`${t('appliedSuccessfully')}`);
          } else {
            switch (status) {
              case 400:
                toast.error(`${t('accountDeleted')}`);
                break;
              case 418:
                toast.error(`${t('jobTaken')}`);
                break;
              case 403:
                toast.error(`${t('alreadyAppliedForaJob')}`);
                break;
              case 406:
                toast.error(`${t('alreadyBookForaJob')}`);
                break;
              case 410:
                toast.error(`${t('otherAlreadyBooked')}`);
                break;
              default:
                toast.error(message);
            }
          }
        },
      }),
    );
  };
  return (
    <section className="content-section">
      <Head title={t('title.jobWallDetails')} />
      <Container>
        <div className="box-wrapper">
          <div className="form-back-wrapper">
            <div
              className="form-back"
              onClick={() => {
                history.push({
                  pathname: `/${labelRoles.employee}/job-wall`,
                });
              }}></div>
            <div className="contact-btn-wrapper">
              <p>
                {t('needHelp')} <br /> {t('contact')}
              </p>
              <Link to="/contact" className="btn">
                {t('home.contact')}
              </Link>
            </div>
          </div>
          {applyLoading && <Loader />}
          {loading ? (
            <div className="d-flex align-items-center justify-content-center py-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <>
              {!job ? (
                <></>
              ) : (
                <>
                  <h3>{job.eventName}</h3>
                  <a
                    className="link text-semibold"
                    href={`/${labelRoles.employee}/job-wall?eventId=${job.eventID}`}>
                    {t('showjobsfromthisevent')}
                  </a>
                  <h3 className="headline-with-line">JOB: {job.jobPosition}</h3>
                  <Row>
                    <Col lg="6">
                      <p className="small-text text-uppercase text-semibold pb-3">
                        {t('requestOverview.description')}
                      </p>
                      <p className="small-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlDecode(job.jobDescription),
                          }}
                        />
                      </p>
                    </Col>
                    <Col lg="6">
                      <p className="small-text text-uppercase text-semibold pb-3">
                        {t('requestOverview.staffRequirement')}
                      </p>
                      <p className="small-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlDecode(job.jobRequirements),
                          }}
                        />
                      </p>
                    </Col>
                  </Row>
                  <h3 className="headline-with-line">
                    {t('Operational_data')}
                  </h3>
                  <Table columns={columns} data={data} />

                  <div className="form-wrapper border-bottom remarksForEmployee">
                    <h3 className="m-0">
                      {t('jobWallStaff.remarksForEmployee')}
                    </h3>
                    <Row>
                      <Col>
                        <Textarea
                          // label={t('jobWallStaff.remarksForEmployee')}
                          placeholder={t('jobWallStaff.remarksForEmployee')}
                          name="empRemarks"
                          rows="2"
                          value={formik.values.empRemarks}
                          onChange={formik.handleChange}
                          touched={formik.touched.empRemarks}
                        />
                      </Col>
                    </Row>
                  </div>
                  <p className="small-text placeholder-text ms-0">
                    {t('jobWallStaff.travelcoststext')}
                    <br />*{t('jobWallStaff.textOne')}
                    <br /> {t('jobWallStaff.otherdetail')}
                  </p>
                  <div className="buttonInfoOverlay">
                    {/* {getButtonInfoOverlay()} */}
                    <div className="iBtnTooltip">
                      {t('myJob.bindingApplication')}
                    </div>
                    <svg
                      width="22"
                      height="23"
                      xmlns="http://www.w3.org/2000/svg"
                      className="popover-icon">
                      <g
                        transform="translate(0 .5)"
                        fill="none"
                        fillRule="evenodd">
                        <circle fill="#41a9c7" cx="11" cy="11" r="11" />
                        <path
                          d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                          fill="#FFF"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="job-wall-detail-button">
                    {job.duplicateIds && job.duplicateIds.length ? (
                      <div className="employee-registration-note d-flex justify-content-end align-items-start">
                        <div className="employee-registration-note__icon">
                          <img
                            src="data:image/svg+xml,%3Csvg%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2070%2070%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M32.96%2012.867c.89-1.6%203.19-1.6%204.08%200l23.333%2042c.864%201.555-.26%203.466-2.04%203.466H11.667c-1.78%200-2.904-1.91-2.04-3.466l23.333-42zM35%2018.805L15.632%2053.667h38.736L35%2018.805zm-2.333%2011.528a2.333%202.333%200%20114.666%200v9.334a2.333%202.333%200%2011-4.666%200v-9.334zm0%2016.334a2.333%202.333%200%20114.666%200V49a2.333%202.333%200%2011-4.666%200v-2.333z%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fillRule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cuse%20fill%3D%22%23000%22%20fillRule%3D%22nonzero%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3Cg%20fill%3D%22%2341A9C7%22%20mask%3D%22url(%23b)%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                            alt=""
                            width="70"
                            height="70"
                          />
                        </div>
                        <div className="employee-registration-note__content">
                          <p className="text-uppercase mb-2">
                            <strong>{t('note')}</strong>
                          </p>
                          <p className="small-text">
                            {t('myJob.applyformultiplepositions')}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Button
                      disabled={isSubmitting}
                      type="button"
                      className="btn"
                      label={t('apply')}
                      onClick={handleApply}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default JobWallDetails;
